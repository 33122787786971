import { useGetConnectionStatusQuery } from 'clipsal-cortex/src/features/profile/integrations/tesla/teslaApi';
import { useSelector } from 'react-redux';

import { selectSite } from '../../../site/siteSlice';

export function useTeslaIsConnected() {
  const { site_id: siteId } = useSelector(selectSite);
  const { data: connectionStatus, isLoading, isError } = useGetConnectionStatusQuery(siteId);
  const isConnected = !isError && !isLoading && connectionStatus?.status === 'success';
  return {
    isConnected,
    isLoading,
    isError,
  };
}
