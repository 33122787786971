import React, { useEffect, useMemo } from 'react';
import { Box, Center, Heading, Text, useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';

import { VPPProgramStatus } from 'clipsal-cortex-types/src/api/api-saturn-vpp';
import CenteredLoader from 'clipsal-cortex-ui/src/components/CenteredLoader';

import { RTKQError } from '../../../common/api/api-helpers';
import ArcButton from '../../../common/components/ArcButton';
import SlidingAnimationPageBase from '../../../common/components/SlidingAnimationPageBase';
import { useParamBackUrl } from '../../../common/hooks/use-param-back-url';
import { MANUFACTURER_ID_SATURN } from '../../../features/devices/devices-helper';
import { selectInverters } from '../../../features/site/siteSlice';
import CustomVppIcon from './CustomVPPIcon';
import { getProgramByStatus, useGetEligibleVppProgramsToJoin } from './helpers';
import { useGetVppProgramOffers, useGetVppProgramsStatus, useGetVppUtilities, useGetVppUtilityStatus } from './vppApi';
import { VPPHelpGuide } from './VPPHelpGuide';

export function VPPHome() {
  const saturnInverter = useSelector(selectInverters).find((device) =>
    [MANUFACTURER_ID_SATURN].includes(device.manufacturer_id)
  );
  const { t } = useTranslation();
  const backURL = useParamBackUrl('../../../account/dashboard?direction=back');

  return (
    <SlidingAnimationPageBase backURL={backURL} title={t('VPP.title')}>
      <Box mx={4} mb={4}>
        {!!saturnInverter?.id && <VPPWizardRedirect inverterId={saturnInverter.id} />}
      </Box>
    </SlidingAnimationPageBase>
  );
}

/*
The following component is responsible for facilitating the user's journey through VPP participation.
The user's journey is as follows:
1️⃣ To be eligible to join a program, the user must provide their utility provider and utility account number.
2️⃣ The user will receive a notification from Saturn Cloud when they are eligible to join a VPP program.
3️⃣ The user accepts the offer in SE Home. Their enrolment is pending and get a notification if approved/denied.
4️⃣ Once the user's inverter is enrolled in a VPP program, they can view current & upcoming VPP events and opt out.
@returns The redirect/UI component, to be returned by the caller component as JSX.
*/
function VPPWizardRedirect({ inverterId }: { inverterId: number }) {
  const { t } = useTranslation();
  const toast = useToast({ duration: 3000, isClosable: true });
  const {
    data: vppUtilityStatus,
    isLoading: isVppUtilityLoading,
    error: vppUtilityStatusError,
  } = useGetVppUtilityStatus(inverterId);
  const {
    data: programsStatus,
    isLoading: isProgramStatusLoading,
    isError: isProgramStatusError,
    isFetching,
  } = useGetVppProgramsStatus(inverterId);
  const {
    offers: eligibleProgramOffers,
    isLoaded: isEligibleProgramOffersLoaded,
    isError: isEligibleProgramOffersError,
  } = useGetEligibleVppProgramsToJoin(inverterId);

  // We can ignore 404 errors for the VPP utility status, as this just means the user is not enrolled in VPP yet.
  const isError =
    (vppUtilityStatusError as RTKQError)?.status !== 404 || isProgramStatusError || isEligibleProgramOffersError;

  const isLoaded = !isProgramStatusLoading && !isVppUtilityLoading && isEligibleProgramOffersLoaded && !isFetching;

  useEffect(() => {
    if (isLoaded && isError) {
      toast.closeAll();
      toast({
        title: t('VPP.error getting vpp data'),
        description: `${t('Common.please try again')} ${t('Common.if this persists contact support')}`,
        status: 'error',
      });
    }
  }, [isLoaded, isError]);

  const VPPWizardRedirectComponent = useMemo(() => {
    if (!isLoaded) return <CenteredLoader text={`${t('Common.loading')}...`} isFullHeight />;

    if (!vppUtilityStatus.length) {
      return <PreEnrolmentForm />;
    }

    // The current scope for VPP is limited to one program per inverter, here we are assuming the user --
    // -- will only ever be enrolled in one program.
    const enrolledProgram = getProgramByStatus(programsStatus, 'Enrolled');
    if (enrolledProgram) return <Navigate to={`../active-program`} />;

    // Otherwise if they are eligible for any programs navigate to the join program screen
    const eligibleProgram = getProgramByStatus(programsStatus, 'Eligible');

    if (eligibleProgram) {
      if (eligibleProgramOffers.length > 0) return <Navigate to={`../join-program`} />;
      // If an eligible program is found, but no offers are available, we display a message to the user
      return <NoVPPOffers />;
    }

    const pendingProgram = getProgramByStatus(programsStatus, 'Pending');
    if (pendingProgram) return <PendingEnrolment inverterId={inverterId} program={pendingProgram} />;

    const deniedProgram = getProgramByStatus(programsStatus, 'Ineligible');
    if (deniedProgram) return <EnrolmentDenied />;

    return <VPPHelpGuide />;
  }, [isLoaded, vppUtilityStatus, programsStatus, eligibleProgramOffers, isError]);

  return VPPWizardRedirectComponent;
}

const PreEnrolmentForm = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <VPPHelpGuide>
      <ArcButton
        arcColor="#3DCD58"
        minWidth="80%"
        mb={4}
        py={6}
        onClick={() => navigate('../enrol?direction=forward')}
        data-testid="continue-button"
      >
        {t('Common.get started')}
      </ArcButton>
    </VPPHelpGuide>
  );
};

const NoVPPOffers = () => {
  const { t } = useTranslation();
  return (
    <Box data-testid="no-vpp-offers-screen">
      <CustomVppIcon iconColor={'#FF5B44'} />
      <Heading my={2} textAlign="center">
        {t('VPP.no programs available title')}
      </Heading>
      <Text textAlign="center" mb={2}>
        {t('VPP.no programs available description 1')}
      </Text>
      <Text textAlign="center" mb={2}>
        {t('VPP.no programs available description 2')}
      </Text>
    </Box>
  );
};

const EnrolmentDenied = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Box data-testid="vpp-enrolment-denied-screen">
      <CustomVppIcon iconColor={'#FF5B44'} />
      <Heading my={2} textAlign="center">
        {t('VPP.enrolment denied title')}
      </Heading>
      <Text textAlign="center" mb={4}>
        {t('VPP.enrolment denied description')}
      </Text>
      <Center>
        <ArcButton
          arcColor={'#FF5B44'}
          w={'80%'}
          mt={6}
          onClick={() => navigate(`../../../account/support`)}
          data-testid="contact-support-button"
        >
          {t('Common.contact support')}
        </ArcButton>
      </Center>
    </Box>
  );
};

function PendingEnrolment({ program, inverterId }: { program: VPPProgramStatus; inverterId: number }) {
  const { utilities, isLoading: isVppUtilitiesLoading, error: vppUtilitiesError } = useGetVppUtilities(inverterId);
  const {
    programOffers,
    isLoading: isProgramOffersLoading,
    error: vppProgramOffersError,
  } = useGetVppProgramOffers(inverterId);
  const isLoaded = !isVppUtilitiesLoading && !isProgramOffersLoading;
  const isError = vppProgramOffersError || vppUtilitiesError;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast({ duration: 3000, isClosable: true });

  useEffect(() => {
    if (isLoaded && isError) {
      toast.closeAll();
      toast({
        title: t('VPP.error getting vpp data'),
        description:
          (vppProgramOffersError as RTKQError)?.originalResponse?.data?.detail ??
          `${t('Common.please try again')} ${t('Common.if this persists contact support')}`,
        status: 'error',
      });
    }
  }, [isLoaded, isError]);

  // Find the program utility details via the program's utility ID
  const programUtilityId = useMemo(
    () =>
      programOffers?.find((offer) => offer.program.m_rid === program?.equipment_program?.program.m_rid)?.program.utility
        .m_rid,
    [programOffers, program]
  );
  const programUtility = useMemo(
    () => utilities.find((utility) => utility.m_rid === programUtilityId),
    [utilities, programUtilityId]
  );

  return (
    <Box data-testid="vpp-enrolment-pending-screen">
      <CustomVppIcon iconColor={'orange'} />
      <Heading my={2} textAlign="center">
        {t('VPP.enrolment pending title')}
      </Heading>
      {isLoaded ? (
        <>
          <Text
            mb={4}
            textAlign="center"
            data-testid="vpp-enrolment-pending-description"
            hidden={!programUtility || !program}
          >
            {t('VPP.enrolment pending description 1', {
              utilityName: programUtility?.name,
              programName: program?.equipment_program?.program.name,
            })}
          </Text>
          <Text mb={4} textAlign="center">
            {t('VPP.enrolment pending description 2')}
          </Text>
          <Center>
            <ArcButton
              arcColor={'#FF5B44'}
              w={'80%'}
              mt={6}
              onClick={() => navigate(`../../../account/support`)}
              data-testid="contact-support-button"
            >
              {t('Common.contact support')}
            </ArcButton>
          </Center>
        </>
      ) : (
        <CenteredLoader text={`${t('Common.loading')}...`} isFullHeight />
      )}
    </Box>
  );
}
