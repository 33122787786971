import React from 'react';
import { Browser } from '@capacitor/browser';
import { Box, Center, Heading, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { AccountIcon, BatteryIcon } from 'clipsal-cortex-icons/src/custom-icons';
import CenteredLoader from 'clipsal-cortex-ui/src/components/CenteredLoader';

import ArcButton from '../../../../common/components/ArcButton';
import SlidingAnimationPageBase from '../../../../common/components/SlidingAnimationPageBase';
import { getRedirectURI } from '../../../auth/auth-helpers';
import { selectSite } from '../../../site/siteSlice';
import { MIN_PAGE_HEIGHT } from '../constants';
import { TESLA_CLIENT_ID, TESLA_SCOPES } from './constants';
import { useGetAvailableBatteriesQuery } from './teslaApi';
import { TeslaAvailableDevice } from './TeslaAvailableDevice';
import { TeslaDisconnectButton } from './TeslaDisconnectButton';
import { useTeslaIsConnected } from './useTeslaIsConnected';

export function TeslaConnect() {
  const { t } = useTranslation();
  const { site_id: siteId } = useSelector(selectSite);
  const { isConnected, isLoading: isConnectedLoading } = useTeslaIsConnected();
  const { data: devices, isLoading: isBatteriesLoading } = useGetAvailableBatteriesQuery(siteId);
  const isLoading = isConnectedLoading || isBatteriesLoading;

  async function handleConnect() {
    const redirectURI = `${getRedirectURI()}/tesla`;
    const url =
      'https://auth.tesla.com/oauth2/v3/authorize' +
      `?client_id=${TESLA_CLIENT_ID}` +
      `&redirect_uri=${redirectURI}` +
      `&response_type=code` +
      `&scope=${TESLA_SCOPES.join(' ')}` +
      `&state=siteID:${siteId}`;
    /* istanbul ignore if -- @preserve */
    await Browser.open({ url, windowName: '_self' });
  }

  return (
    <SlidingAnimationPageBase title={t('Integrations.title')} backURL={'../home?direction=back'}>
      <Box px={3}>
        <CenteredLoader hidden={!isLoading} text={`${t('Common.loading')}...`} />
        {!isConnected && !isLoading && (
          <Center flexDirection="column" px={3} mt={5} textAlign="center">
            <AccountIcon w="70%" h="70%" my={4} />
            <Heading mt={2}>{t('Integrations.Tesla.add toast header')}</Heading>
            <Text mt={1}>{t('Integrations.Tesla.add toast subHeader')}</Text>
            <ArcButton
              data-testid={'add-tesla-integration'}
              onClick={handleConnect}
              mt={8}
              w={'80%'}
              arcColor="#3DCD57"
            >
              {t('Common.continue')}
            </ArcButton>
          </Center>
        )}
        {isConnected && !isLoading && (
          <>
            <Box overflowY={'auto'} height={`calc(${MIN_PAGE_HEIGHT} - 120px)`}>
              {!!devices?.length ? (
                <>
                  <Box>{`Select Devices From Your Account`.toUpperCase()}</Box>
                  {devices.map((device) => (
                    <TeslaAvailableDevice key={`${device.site_id}-${device.device_name}`} device={device} />
                  ))}
                </>
              ) : (
                <Center flexDirection="column" px={3} mt={5} textAlign="center">
                  <BatteryIcon w="70%" h="70%" my={4} />
                  <Heading mt={2}>{t('Integrations.Tesla.no batteries title')}</Heading>
                  <Text mt={1}>{t('Integrations.Tesla.no batteries description')}</Text>
                </Center>
              )}
            </Box>

            <Center mt="auto" mb={5} flexDir={'column'}>
              <TeslaDisconnectButton />
            </Center>
          </>
        )}
      </Box>
    </SlidingAnimationPageBase>
  );
}
