import React, { useEffect, useMemo } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertIcon,
  Box,
  Divider,
  Flex,
  Radio,
  Skeleton,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import ArcButton from '../../../common/components/ArcButton';
import SEHomeCard from '../../../common/components/SEHomeCard';
import SlidingAnimationPageBase from '../../../common/components/SlidingAnimationPageBase';
import { useParamBackUrl } from '../../../common/hooks/use-param-back-url';
import { selectBatteries, selectSite } from '../../site/siteSlice';
import { useTariffs } from '../../tariff/tariffApi';
import { ENERGY_MODES_UI_CONFIG, STANDARD_MODES, TEMPORARY_MODES } from './energy-modes-helpers';
import EnergyModeListItem from './EnergyModeListItem';
import { useEnergyModeChange } from './use-energy-mode-change';

export function EnergyModesHome() {
  const { t } = useTranslation();
  const { devices, isOnboarding } = useSelector(selectSite);
  const siteBatteries = useSelector(selectBatteries);
  const navigate = useNavigate();
  const { isLoading: isLoadingTariffs, tariffs } = useTariffs();
  const { isPending, isLoading, isError, selectedMode, selectedType, handleChangeEnergyMode } = useEnergyModeChange();
  const toast = useToast();

  useEffect(() => {
    const pendingToastId = 'pending-toast';
    if (isPending && !toast.isActive(pendingToastId)) {
      toast({
        id: pendingToastId,
        status: 'loading',
        title: t('Energy Modes.pending setting mode', {
          energyMode: t(`Energy Modes.${selectedMode}.title`),
        }),
        duration: null,
      });
    } else if (!isPending) {
      toast.closeAll();
    }
  }, [isPending]);

  const standardEnergyModes = useMemo(() => {
    let modes = [...STANDARD_MODES];

    // if site is not on TOU tariff, hide tou mode
    const siteTariffType = tariffs[0]?.tariff?.tariff_type || '';
    if (siteTariffType !== 'TOU') modes = modes.filter((mode) => mode !== 'TOU');

    // if site has no battery or has a JA12 compliant device, hide battery max mode
    const siteHasJa12CompliantDevice = devices.some((device) => device.ja12_compliance);
    if (!siteBatteries.length || siteHasJa12CompliantDevice) modes = modes.filter((mode) => mode !== 'BATTERY_MAX');

    // if site has no battery, hide self consumption mode
    if (!siteBatteries.length) modes = modes.filter((mode) => mode !== 'SELF_CONSUMPTION');

    return modes;
  }, [tariffs, siteBatteries, devices]);

  const temporaryEnergyModes = useMemo(() => {
    let modes = [...TEMPORARY_MODES];

    // if site has no battery, hide storm mode
    if (!siteBatteries.length) modes = modes.filter((mode) => mode !== 'STORM');

    return modes;
  }, [siteBatteries]);

  const backURL = useParamBackUrl('../../../account/dashboard?direction=back');

  return (
    <SlidingAnimationPageBase backURL={backURL} title={t('Energy Modes.energy modes')}>
      <Box mx={4} mb={4}>
        <Text mb={5}>{t('Energy Modes.this sets the config')}</Text>

        <Skeleton isLoaded={!isLoading && !isLoadingTariffs} borderRadius={5} borderBottomRightRadius="25px">
          {isError && (
            <Alert status="error" variant="left-accent" data-testid="error-alert">
              <AlertIcon />
              {t('Energy Modes.error fetching mode')}
            </Alert>
          )}
          <SEHomeCard pt={1} pb={3} pr={0} pl={0} borderTopLeftRadius="5px">
            <Accordion allowToggle defaultIndex={[selectedType === 'TEMPORARY' ? 1 : 0]} borderBottomWidth={0}>
              {!!standardEnergyModes.length && (
                <AccordionItem borderTop="none">
                  <AccordionButton py={4} borderBottomWidth={1} _hover={{ bg: 'unset' }}>
                    <Flex flex="1">
                      <Radio
                        isChecked={selectedType === 'STANDARD'}
                        size="lg"
                        mr={3}
                        data-testid="standard-mode-setting"
                        isReadOnly={true}
                        onClick={(e) => e.stopPropagation()}
                      />
                      {selectedType === 'STANDARD' ? (
                        <Text>
                          {t('Energy Modes.standard mode')}: {t('Energy Modes.active')}
                        </Text>
                      ) : (
                        <Text opacity={0.7}>
                          {t('Energy Modes.standard mode')}: {t('Energy Modes.inactive')}
                        </Text>
                      )}
                    </Flex>
                    <AccordionIcon boxSize={8} color={'customGrey.400'} />
                  </AccordionButton>
                  <AccordionPanel pb={4}>
                    {standardEnergyModes.map((energyMode) => {
                      const { icon: Icon, route } = ENERGY_MODES_UI_CONFIG[energyMode];
                      return (
                        <Box key={energyMode}>
                          <EnergyModeListItem
                            title={t(`Energy Modes.${energyMode}.title`)}
                            text={t(`Energy Modes.${energyMode}.text`)}
                            Icon={Icon}
                            onClick={() => handleChangeEnergyMode(energyMode)}
                            isChecked={selectedMode === energyMode}
                            isDisabled={isPending}
                            route={route}
                            data-testid={`standard-energy-mode-setting-${energyMode.toLowerCase().replace(/_/g, '-')}`}
                          />
                          <Divider borderColor={'rgba(151, 151, 151, 0.3)'} ml={5} />
                        </Box>
                      );
                    })}
                  </AccordionPanel>
                </AccordionItem>
              )}
              <AccordionItem borderTop="none" borderBottom="none">
                <AccordionButton py={4} _hover={{ bg: 'unset' }}>
                  <Flex flex="1">
                    <Radio
                      isChecked={selectedType === 'TEMPORARY'}
                      size="lg"
                      mr={3}
                      data-testid="temporary-mode-setting"
                      onClick={(e) => e.stopPropagation()}
                    />
                    {selectedType === 'TEMPORARY' ? (
                      <Text>
                        {t('Energy Modes.temporary mode')}: {t('Energy Modes.active')}
                      </Text>
                    ) : (
                      <Text opacity={0.7}>
                        {t('Energy Modes.temporary mode')}: {t('Energy Modes.inactive')}
                      </Text>
                    )}
                  </Flex>
                  <AccordionIcon boxSize={8} color={'customGrey.400'} />
                </AccordionButton>
                <AccordionPanel pb={4} borderTopWidth={1}>
                  {temporaryEnergyModes.map((energyMode, index, tempModes) => {
                    const isLastElement = index == tempModes.length - 1;
                    const { icon: Icon, route } = ENERGY_MODES_UI_CONFIG[energyMode];
                    return (
                      <Box key={energyMode}>
                        <EnergyModeListItem
                          title={t(`Energy Modes.${energyMode}.title`)}
                          text={t(`Energy Modes.${energyMode}.text`)}
                          Icon={Icon}
                          onClick={() => handleChangeEnergyMode(energyMode)}
                          isChecked={selectedMode === energyMode}
                          isDisabled={isPending}
                          route={route}
                          data-testid={`temporary-energy-mode-setting-${energyMode.toLowerCase().replace(/_/g, '-')}`}
                        />
                        {!isLastElement && <Divider borderColor={'rgba(151, 151, 151, 0.3)'} ml={5} />}
                      </Box>
                    );
                  })}
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </SEHomeCard>
        </Skeleton>

        <ArcButton
          hidden={!isOnboarding}
          arcColor="#3DCD58"
          minWidth="280px"
          py={6}
          data-testid="continue-btn"
          onClick={() => navigate('../finish')}
          mt={8}
        >
          {t('Common.continue')}
        </ArcButton>
      </Box>
    </SlidingAnimationPageBase>
  );
}
