import React from 'react';
import { Center, Flex, Skeleton, Switch, Text, useColorModeValue, useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { AvailableTeslaBattery } from 'clipsal-cortex-types/src/api/api-tesla';

import { useReduxDispatch } from '../../../../app/store';
import SEHomeCard from '../../../../common/components/SEHomeCard';
import { IS_PRODUCTION } from '../../../../common/constants';
import { SEHomeBatteryIcon } from '../../../../styles/custom-icons';
import { useCommissionBatteryMutation, useDecommissionBatteryMutation } from '../../../battery/batteryApi';
import { fetchSite, selectSite } from '../../../site/siteSlice';

interface Props {
  device: AvailableTeslaBattery;
}

export function TeslaAvailableDevice({ device }: Props) {
  const { site_id: externalSiteId, device_name, active, site_name } = device;
  const { site_id: siteId } = useSelector(selectSite);
  const { t } = useTranslation();
  const textColor = useColorModeValue('customGrey.800', 'customGrey.300');
  const toast = useToast({ isClosable: true });
  const dispatch = useReduxDispatch();
  const [commissionBattery, { isLoading: isCreateLoading }] = useCommissionBatteryMutation();
  const [decommissionBattery, { isLoading: isDeleteLoading }] = useDecommissionBatteryMutation();
  const isLoading = isCreateLoading || isDeleteLoading;

  const handleSelect = async (checked: boolean) => {
    if (checked) {
      try {
        await commissionBattery({
          siteId: Number(siteId),
          body: {
            external_site_id: externalSiteId,
            provider: 'TESLA',
          },
        }).unwrap();
        await dispatch(fetchSite({ siteID: siteId, isProduction: IS_PRODUCTION }));
        toast({
          title: t('Integrations.Tesla.commission battery success'),
          status: 'success',
        });
      } catch (e) {
        toast({
          title: t('Integrations.Tesla.commission battery error'),
          status: 'error',
        });
      }
    } else {
      try {
        await decommissionBattery({
          siteId: Number(siteId),
          body: {
            external_site_id: externalSiteId,
            provider: 'TESLA',
          },
        }).unwrap();
        await dispatch(fetchSite({ siteID: siteId, isProduction: IS_PRODUCTION }));
        toast({
          title: t('Integrations.Tesla.decommission battery success'),
          status: 'success',
        });
      } catch (e) {
        toast({
          title: t('Integrations.Tesla.decommission battery error'),
          status: 'error',
        });
      }
    }
  };

  return (
    <SEHomeCard cursor="pointer" p={4} borderTopLeftRadius="5px" mb={2} data-testid={'tesla-device'}>
      <Flex align="center">
        <Center marginRight="auto">
          <SEHomeBatteryIcon w={8} h={8} mr={4} />
          <Flex direction="column">
            <Text fontSize={17} fontWeight={400} lineHeight={1.2} overflowWrap="anywhere">
              {device_name}
            </Text>
            <Text fontSize={15} color={textColor} fontWeight={400} lineHeight={1.1} mr={1}>
              {site_name}
            </Text>
          </Flex>
        </Center>
        <Flex align="center">
          <Skeleton isLoaded={!isLoading} borderRadius={5}>
            <Switch
              data-testid="tesla-device-switch"
              isChecked={active}
              onChange={(e) => handleSelect(e.currentTarget.checked)}
              size="lg"
              variant="solarGreen"
            />
          </Skeleton>
        </Flex>
      </Flex>
    </SEHomeCard>
  );
}
