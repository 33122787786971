import React, { useCallback } from 'react';
import { useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import CenteredLoader from 'clipsal-cortex-ui/src/components/CenteredLoader';
import useOnMount from 'clipsal-cortex-utils/src/hooks/use-on-mount';

import { getRedirectURI } from '../../../auth/auth-helpers';
import { TESLA_SCOPES } from './constants';
import { useCreateConnectionMutation } from './teslaApi';

export function TeslaOAuthRedirect() {
  const { t } = useTranslation();
  const toast = useToast({ isClosable: true });
  const [search] = useSearchParams();
  const navigate = useNavigate();
  const [connectTeslaAccountToSite] = useCreateConnectionMutation();

  const connectTeslaAccount = useCallback(async () => {
    const code = search.get('code');
    const state = search.get('state');
    const redirectURI = `${getRedirectURI()}/tesla`;

    if (code && state) {
      const [, siteId] = state.split('siteID:');

      try {
        await connectTeslaAccountToSite({
          siteId: Number(siteId),
          body: {
            code,
            scope: TESLA_SCOPES.join(' '),
            redirect_uri: redirectURI,
          },
        }).unwrap();

        toast({
          title: t('Integrations.Tesla.connect success'),
          status: 'success',
        });

        navigate(`/site/${siteId}/account/hardware/configure/tesla`);
      } catch (e) {
        toast({
          title: t('Integrations.Tesla.connect error'),
          status: 'error',
        });
        navigate(`/site/${siteId}/account/hardware/configure/home`);
      }
    } else {
      toast({
        title: t('Integrations.Tesla.connect error'),
        status: 'error',
      });
      navigate('/');
    }
  }, [search, connectTeslaAccountToSite, toast, navigate]);

  useOnMount(connectTeslaAccount);

  return <CenteredLoader text={t('Integrations.Tesla.connect text')} isFullHeight />;
}
