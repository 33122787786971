import React, { useMemo, useState } from 'react';
import { ScanResult } from '@capacitor-community/barcode-scanner';
import { Wifi } from '@capacitor-community/wifi/src';
import { Center, Heading, Image, Text, useToast } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import wifiConnectionFailed from '../../../../assets/images/inverter_connection_failed.svg';
import inverterQrCodeImg from '../../../../assets/images/inverter_qr_code_scan.svg';
import wifiConnectingImg from '../../../../assets/images/wifi_connecting.svg';
import ScanBarcodeButton from '../../../../common/components/ScanBarcodeButton';
import SlidingAnimationPageBase from '../../../../common/components/SlidingAnimationPageBase';
import TopNavProgressLoader from '../../../../common/components/TopNavProgressLoader';
import { extractWifiCredentials } from '../helpers';

type ConnectionStatus = 'NONE' | 'CONNECTING' | 'FAILED' | 'INVALID_QR_CODE';

/* istanbul ignore next -- @preserve */
export function InverterScanBarcode() {
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const toast = useToast({ isClosable: true });
  const [connectionStatus, setConnectionStatus] = useState<ConnectionStatus>('NONE');
  const { t } = useTranslation();

  async function handleScanBarcode(scanResult: ScanResult) {
    if (scanResult.content) {
      setConnectionStatus('CONNECTING');

      try {
        const { ssid, password } = extractWifiCredentials(scanResult.content);
        try {
          await Wifi.connect({ ssid, password, isHiddenSsid: false });
          navigate(`../enter_wifi_credentials?${search.toString() || ''}`);
        } catch (e) {
          Sentry.captureException(e);
          console.error(e);
          console.error((e as any).toString());
          toast({
            title: t('Set Up Hardware.unable to connect to inverter wap'),
            description: t('Set Up Hardware.try again ensure wifi permission'),
            status: 'error',
          });
          setConnectionStatus('FAILED');
        }
      } catch (e) {
        Sentry.captureException(e);
        console.error(e);
        setConnectionStatus('INVALID_QR_CODE');
        toast({
          title: t('Common.invalid qr code'),
          description: t('Common.please try again'),
          status: 'error',
        });
      }
    }
  }

  let contents = (
    <>
      <Image w={'70%'} my={4} src={inverterQrCodeImg} alt="Inverter sketch" />
      <Heading mt={2}>{t('Set Up Hardware.allow camera')}</Heading>
      <Text mt={1}>
        {t('Set Up Hardware.on your device there is a qr', {
          device: t('Set Up Hardware.inverter'),
        })}{' '}
        {t('Set Up Hardware.use your camera to scan', {
          device: t('Set Up Hardware.inverter'),
        })}
      </Text>
      <ScanBarcodeButton
        onScan={handleScanBarcode}
        buttonText={t('Common.open camera')}
        onScanError={() => setConnectionStatus('NONE')}
        mt={8}
        w={'80%'}
      />
    </>
  );

  if (connectionStatus === 'CONNECTING') {
    contents = (
      <>
        <TopNavProgressLoader />
        <Image w={'70%'} my={4} src={wifiConnectingImg} alt="Connecting to inverter" />
        <Heading mt={2}>{t('Set Up Hardware.searching wifi')}</Heading>
        <Text mt={1}>
          {t('Set Up Hardware.this allows the device', { device: t('Set Up Hardware.schneider inverter') })}
        </Text>
      </>
    );
  }

  if (connectionStatus === 'FAILED') {
    contents = (
      <>
        <Image w={'70%'} src={wifiConnectionFailed} alt="Connection failed" />
        <Heading mt={2}>{t('Set Up Hardware.unable to connect')}</Heading>
        <Text mt={1}>
          {t('Set Up Hardware.unable to connect to device wap', {
            device: t('Set Up Hardware.schneider inverter'),
          })}
        </Text>
        <Text mt={1}>{t('Common.please try again')}</Text>
        <Text mt={1}>{t('Common.if this persists contact support')}</Text>
      </>
    );
  }

  if (connectionStatus === 'INVALID_QR_CODE') {
    contents = (
      <>
        <Image w={'70%'} src={wifiConnectionFailed} alt="QR Code scan failed" />
        <Heading mt={2}>{t('Set Up Hardware.unable to validate qr')}</Heading>
        <Text mt={1}>{t('Set Up Hardware.ensure wifi enabled')}</Text>
        <Text my={1}>{t('Common.please try again')}</Text>
        <Text>{t('Common.if this persists contact support')}</Text>
      </>
    );
  }

  // This ensures when coming from hardware details page, we can go back to the same page when going back
  const urlSearchParamsToGoBack = useMemo(() => {
    const params = new URLSearchParams(search);
    params.set('direction', 'back');
    return params.toString();
  }, []);

  return (
    <SlidingAnimationPageBase
      title={t('Common.device setup')}
      backURL={`../power_up_instructions?${urlSearchParamsToGoBack}`}
    >
      <Center flexDirection="column" px={3} mt={6} textAlign="center">
        {contents}
      </Center>
    </SlidingAnimationPageBase>
  );
}
