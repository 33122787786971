import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import SEHomeCard from '../../../common/components/SEHomeCard';
import SlidingAnimationPageBase from '../../../common/components/SlidingAnimationPageBase';
import { IS_DEMO_LOGIN } from '../../../common/constants';
import { ENV_TYPE_SHORTHAND } from '../../../env-type';
import { SEHomeBatteryIcon, SEHomeInverterIcon, SEHomeMeterIcon } from '../../../styles/custom-icons';
import { selectInverters, selectMeters } from '../../site/siteSlice';
import AccountMenuButton from '../AccountMenuButton';

export function HardwareConfiguration() {
  const inverters = useSelector(selectInverters);
  const meters = useSelector(selectMeters);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const IS_STAGING = ENV_TYPE_SHORTHAND === 'STAGING';
  return (
    <SlidingAnimationPageBase title={t('Set Up Hardware.configure new hardware')} backURL={`../../list?direction=back`}>
      <Box px={3}>
        <Box>{t('Set Up Hardware.configure device').toUpperCase()}</Box>

        <SEHomeCard borderTopLeftRadius={'5px'} borderBottomRightRadius={'0px'} borderBottomLeftRadius={'0px'} p={0}>
          <AccountMenuButton
            data-testid="configure-meter"
            isDisabled={!!meters.length}
            onClick={() => {
              navigate(`../meter`);
            }}
          >
            <Flex align={'center'}>
              <SEHomeMeterIcon w={8} h={8} />
              <Text fontSize={13} fontWeight="normal" ml={3}>
                {t('Set Up Hardware.new device', { device: t('Set Up Hardware.schneider energy monitor') })}
              </Text>
            </Flex>
          </AccountMenuButton>
        </SEHomeCard>

        <SEHomeCard borderTopLeftRadius={'0px'} borderTopRightRadius={'0px'} p={0} mb={4}>
          <AccountMenuButton
            data-testid="configure-inverter"
            isDisabled={!!inverters.length}
            includeBottomDivider={false}
            onClick={() => {
              navigate(`../inverter`);
            }}
          >
            <Flex align={'center'}>
              <SEHomeInverterIcon w={8} h={8} />
              <Text fontSize={13} fontWeight="normal" ml={3}>
                {t('Set Up Hardware.new device', { device: t('Set Up Hardware.schneider inverter') })}
              </Text>
            </Flex>
          </AccountMenuButton>
        </SEHomeCard>
        {IS_STAGING && !IS_DEMO_LOGIN && (
          <>
            <Box>{t('Integrations.title').toUpperCase()}</Box>
            <SEHomeCard borderTopLeftRadius={'5px'} borderBottomLeftRadius={'5px'} p={0}>
              <AccountMenuButton
                data-testid="configure-tesla"
                includeBottomDivider={false}
                onClick={() => {
                  navigate(`../tesla`);
                }}
              >
                <Flex align={'center'}>
                  <SEHomeBatteryIcon w={8} h={8} />
                  <Text fontSize={13} fontWeight="normal" ml={3}>
                    {t('Integrations.Tesla.title')}
                  </Text>
                </Flex>
              </AccountMenuButton>
            </SEHomeCard>
          </>
        )}
      </Box>
    </SlidingAnimationPageBase>
  );
}
