import React from 'react';
import {
  Box,
  Center,
  Flex,
  Heading,
  Image,
  Step,
  StepIndicator,
  Stepper,
  StepStatus,
  Text,
  useColorModeValue,
  useSteps,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import houseAndGridImgDark from '../../../assets/images/house_and_grid_money_dark.svg';
import houseAndGridImgLight from '../../../assets/images/house_and_grid_money_light.svg';
import houseAndSunImgDark from '../../../assets/images/house_and_sun_dark.svg';
import houseAndSunImgLight from '../../../assets/images/house_and_sun_light.svg';
import housesAndGridImgDark from '../../../assets/images/houses_and_grid_dark.svg';
import housesAndGridImgLight from '../../../assets/images/houses_and_grid_light.svg';
import CustomVppIcon from './CustomVPPIcon';

export function VPPHelpGuide({ children }: React.PropsWithChildren) {
  const textColor = useColorModeValue('customGrey.900', 'customGrey.200');
  const iconColor = useColorModeValue('primaryBranding.600', 'primaryBranding.200');
  const stepperColor = useColorModeValue('customGrey.700', 'customGrey.200');
  const step1Image = useColorModeValue(houseAndSunImgDark, houseAndSunImgLight);
  const step2Image = useColorModeValue(housesAndGridImgDark, housesAndGridImgLight);
  const step3Image = useColorModeValue(houseAndGridImgDark, houseAndGridImgLight);

  const { t } = useTranslation();

  const steps = [
    {
      Component: (
        <>
          <Image maxH="175px" src={step1Image} alt="house and sun" mx="auto" />
          <Heading mt={6} mb={2} textAlign="center">
            {t('VPP.earn money title')}
          </Heading>
          <Text textAlign="center" color={textColor}>
            {t('VPP.earn money description')}
          </Text>
        </>
      ),
    },
    {
      Component: (
        <>
          <Image maxH="175px" src={step2Image} alt="houses and grid" mx="auto" />
          <Heading mt={6} mb={2} textAlign="center">
            {t('VPP.apply today title')}
          </Heading>
          <Text textAlign="center" color={textColor}>
            {t('VPP.apply today description 1')}
          </Text>
          <Text textAlign="center" color={textColor}>
            {t('VPP.apply today description 2')}
          </Text>
        </>
      ),
    },
    {
      Component: (
        <>
          <Image maxH="175px" src={step3Image} alt="house and sun" mx="auto" />
          <Heading mt={6} mb={2} textAlign="center">
            {t('VPP.power up title')}
          </Heading>
          <Text textAlign="center" color={textColor}>
            {t('VPP.power up description')}
          </Text>
        </>
      ),
    },
    {
      Component: (
        <>
          <CustomVppIcon iconColor={iconColor} />
          <Heading mt={6} mb={2} textAlign="center">
            {t('VPP.get started title')}
          </Heading>
          <Text textAlign="center" color={textColor}>
            {t('VPP.get started description')}
          </Text>
        </>
      ),
    },
  ];

  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });

  return (
    <Flex alignItems="center" flexDir="column">
      <Box mt={6}>{steps[activeStep].Component}</Box>

      <Center flexDir="column" position="fixed" bottom={0} width="80%">
        <Stepper size="md" index={activeStep} colorScheme="white" my={6}>
          {steps.map((_step, index) => (
            <Step key={index} onClick={() => setActiveStep(index)} data-testid={`step-${index}`}>
              <StepIndicator
                sx={{
                  '[data-status=complete] &': {
                    background: stepperColor,
                    borderColor: stepperColor,
                  },
                  '[data-status=incomplete] &': {
                    background: stepperColor,
                    borderColor: stepperColor,
                  },
                }}
              >
                <StepStatus />
              </StepIndicator>
            </Step>
          ))}
        </Stepper>

        {children}
      </Center>
    </Flex>
  );
}
