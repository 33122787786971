import React, { useMemo } from 'react';
import { Center, Heading, Image, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import inverterImg from '../../../../assets/images/inverter.svg';
import ArcButton from '../../../../common/components/ArcButton';
import SlidingAnimationPageBase from '../../../../common/components/SlidingAnimationPageBase';
import useOnboardingWizardProgress from '../../../home/useOnboardingWizardProgress';
import PermissionNotGranted from '../components/PermissionNotGranted';
import useIOSPermissionCheck from '../use-ios-permission-check';

export function InverterStart() {
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const hasGrantedPermission = useIOSPermissionCheck();
  const { needsToConfigureHardware } = useOnboardingWizardProgress();
  const { t } = useTranslation();

  let contents = (
    <Center flexDirection="column" px={3} mt={5} textAlign="center">
      <Image w={'70%'} src={inverterImg} alt="inverter" my={4} />

      <Heading mt={2}>{t('Set Up Hardware.add device title', { device: t('Set Up Hardware.inverter') })}</Heading>
      <Text mt={1}>{t('Set Up Hardware.add device text', { device: t('Set Up Hardware.inverter') })}</Text>

      <ArcButton
        data-testid={'inverter-continue'}
        onClick={() => {
          navigate(`../power_up_instructions`);
        }}
        mt={8}
        w={'80%'}
        arcColor="#3DCD57"
      >
        {t('Common.continue')}
      </ArcButton>
    </Center>
  );

  if (!hasGrantedPermission) {
    contents = <PermissionNotGranted />;
  }

  const backURL = useMemo(() => {
    if (needsToConfigureHardware) return `../../../../../first_time_hardware_configuration?direction=back`;
    return search.get('backURL') ?? `../../home`;
  }, [needsToConfigureHardware, search]);

  return (
    <SlidingAnimationPageBase title={t('Common.device setup')} backURL={backURL}>
      {contents}
    </SlidingAnimationPageBase>
  );
}
