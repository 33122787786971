import React, { useRef, useState } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Flex,
  FormControl,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { PasswordInput } from '../../../../common/components/PasswordInput';
import { WifiNetwork } from './types';
import { makeRequestWithAuth } from './utils';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: ({ password }: ConnectedWifiNetwork) => void;
  network: WifiNetwork;
};
// The password is returned so it can be potentially reused to reconnect the user back to their home Wi-Fi
type ConnectedWifiNetwork = { password: string };

type State = {
  isSubmitting: boolean;
  password: string;
};

const INITIAL_STATE: State = {
  isSubmitting: false,
  password: '',
};

/* istanbul ignore next -- @preserve */
export default function MeterWiFiPasswordDialog({ isOpen, onClose, onConfirm, network }: Props) {
  const cancelRef = useRef<HTMLButtonElement | null>(null);
  const buttonBorderColor = useColorModeValue('customGrey.400', 'customGrey.600');
  const backgroundColor = useColorModeValue('customGrey.100', 'customGrey.800');
  const [state, setState] = useState<State>(INITIAL_STATE);
  const { isSubmitting, password } = state;
  const toast = useToast({ isClosable: true });
  const [search] = useSearchParams();
  const accountId = search.get('accountId');
  const { t } = useTranslation();

  async function handleConnectDeviceToWifiNetwork() {
    setState((p) => ({ ...p, isSubmitting: true }));

    try {
      search.set('ssid', network.ssid);
      search.set('passphrase', password);
      // @TODO: should we use first in array like this?
      search.set('sec', network.security[0]);
      search.set('h', '0');

      const body = {
        ssid: network.ssid,
        passphrase: password,
        sec: network.security[0],
        h: 0,
      };

      await makeRequestWithAuth(accountId, 'POST', '/api/wifi_connect', body, {
        'Content-Type': 'application/x-www-form-urlencoded',
      });
      setState((p) => ({ ...p, isSubmitting: false }));
      onConfirm({ password });
      onClose();
    } catch (e) {
      const error = e as Error;
      Sentry.captureException(e);
      console.error(e);

      if (error?.name === 'AccountLockedSEM') {
        toast({
          title: t('Set Up Hardware.sem incorrectly configured'),
          description: `${t('Common.please contact support')} ${t('Set Up Hardware.sem incorrectly configured')}`,
          status: 'error',
        });
      } else {
        console.error(e);
        toast({
          title: t('Set Up Hardware.error connecting to wifi'),
          description: `${t('Common.please try again')} ${t('Common.if this persists contact support')}`,
          status: 'error',
        });
        setState((p) => ({ ...p, isSubmitting: false }));
        onClose();
      }
    }
  }

  return (
    <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent top={'220px'} bgColor={backgroundColor} mx={8} rounded={16} mt={30} overflowX="hidden">
          <AlertDialogHeader textAlign="center" pt={6} pb={0} fontSize="lg" fontWeight="bold" mx="auto">
            {t('Set Up Hardware.enter wifi pw')}
          </AlertDialogHeader>

          <AlertDialogBody my={4}>
            <FormControl mt={3}>
              <PasswordInput
                onChange={(password) => setState((p) => ({ ...p, password }))}
                value={password}
                backgroundColor={backgroundColor}
                borderColor={'#9FA0A4'}
              />
            </FormControl>
          </AlertDialogBody>

          <Flex w="100%" justifyContent={'space-around'}>
            <Button
              w="100%"
              rounded={0}
              py={6}
              fontWeight="normal"
              borderRight="1px solid"
              borderTop="1px solid"
              borderColor={buttonBorderColor}
              variant="ghost"
              onClick={onClose}
              ref={cancelRef}
            >
              {t('Common.cancel')}
            </Button>

            <Button
              color="#008A16"
              w="100%"
              rounded={0}
              isLoading={isSubmitting}
              py={6}
              variant="ghost"
              borderTop="1px solid"
              borderColor={buttonBorderColor}
              onClick={handleConnectDeviceToWifiNetwork}
            >
              {t('Common.confirm')}
            </Button>
          </Flex>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
