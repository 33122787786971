import { AvailableTeslaBattery, TeslaConnection, TeslaConnectionStatus } from 'clipsal-cortex-types/src/api/api-tesla';

import { baseApi } from '../../../../app/services/baseApi';

export const teslaApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getConnectionStatus: build.query<TeslaConnectionStatus, number>({
      query: (siteId) => `/v1/sites/${siteId}/tesla_connection`,
      providesTags: ['TeslaAccountConnection'],
    }),
    deleteConnection: build.mutation<void, number>({
      query: (siteId) => ({
        url: `/v1/sites/${siteId}/tesla_connection`,
        method: 'DELETE',
      }),
      invalidatesTags: ['TeslaAccountConnection'],
    }),
    createConnection: build.mutation<TeslaConnectionStatus, { siteId: number; body: TeslaConnection }>({
      query: ({ siteId, body }) => ({
        url: `/v1/sites/${siteId}/tesla_connection`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['TeslaAccountConnection'],
    }),
    getAvailableBatteries: build.query<AvailableTeslaBattery[], number>({
      query: (siteId) => `/v1/sites/${siteId}/tesla_batteries`,
      providesTags: ['AvailableTeslaBatteries'],
    }),
  }),
});

export const {
  useGetConnectionStatusQuery,
  useDeleteConnectionMutation,
  useCreateConnectionMutation,
  useGetAvailableBatteriesQuery,
} = teslaApi;
