import React, { useEffect, useMemo } from 'react';
import {
  Box,
  Center,
  Divider,
  FormControl,
  FormErrorMessage,
  Heading,
  Input,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import CenteredLoader from 'clipsal-cortex-ui/src/components/CenteredLoader';

import ArcButton from '../../../common/components/ArcButton';
import { CustomSelect, SelectOption } from '../../../common/components/CustomSelect';
import SEHomeCard from '../../../common/components/SEHomeCard';
import SlidingAnimationPageBase from '../../../common/components/SlidingAnimationPageBase';
import { useParamBackUrl } from '../../../common/hooks/use-param-back-url';
import { selectInverters, selectSite } from '../../site/siteSlice';
import CustomVppIcon from './CustomVPPIcon';
import { ProvideUtilityStatus, useCreateVppUtilityStatusMutation, useGetVppUtilities } from './vppApi';

export type VPPFormData = {
  energyProvider: SelectOption;
  energyAccountNumber: string;
};

export function EnrolmentForm() {
  const site = useSelector(selectSite);
  const [{ id: inverterId }] = useSelector(selectInverters);
  const textColor = useColorModeValue('customGrey.900', 'customGrey.200');
  const iconColor = useColorModeValue('primaryBranding.600', 'primaryBranding.200');
  const backgroundColor = useColorModeValue('#FFFFFF', 'customGrey.900');
  const toast = useToast({ isClosable: true });
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { utilities, isLoading, isError: isUtilitiesError } = useGetVppUtilities(inverterId);
  const [createVppUtilityStatus] = useCreateVppUtilityStatusMutation();
  const backURL = useParamBackUrl('../home?direction=back');

  const schema = yup.object().shape({
    energyProvider: yup.object().required().label(t('Energy Rates.energy provider')),
    energyAccountNumber: yup.string().max(50).required().label(t('VPP.energy account number')),
  });

  const {
    control,
    register,
    handleSubmit: handleFormSubmit,
    formState: { errors, isSubmitting },
  } = useForm<VPPFormData>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (!isLoading && isUtilitiesError)
      toast({
        title: t('VPP.error getting vpp data'),
        description: `${t('Common.please try again')} ${t('Common.if this persists contact support')}`,
        status: 'error',
      });
  }, [isLoading, isUtilitiesError]);

  const utilityOptions = useMemo(
    () => utilities.map((utility) => ({ label: utility.name, value: utility.m_rid })),
    [utilities]
  );

  async function handleSubmit(values: VPPFormData) {
    const body: ProvideUtilityStatus = {
      utility_mrid: values.energyProvider.value,
      utility_account_number: values.energyAccountNumber,
    };

    try {
      await createVppUtilityStatus({ inverterId, body }).unwrap();
      navigate(`../home?direction=forward`);
    } catch (e) {
      console.error(e);
      toast({
        title: t('VPP.error providing utility details'),
        description: `${t('Common.please try again')} ${t('Common.if this persists contact support')}`,
        status: 'error',
        duration: 3000,
      });
    }
  }

  return (
    <SlidingAnimationPageBase backURL={backURL} title={t('VPP.apply for vpp')}>
      <Box mx={4} mb={4}>
        <CustomVppIcon iconColor={iconColor} />
        <Heading size="lg" textAlign="center" my={2}>
          {t('VPP.enrol to confirm eligibility title')}
        </Heading>

        <Text textAlign="center" color={textColor}>
          {t('VPP.enrol to confirm eligibility subtitle')}
        </Text>

        <Divider my={6} />

        <Text>{t('VPP.account details').toUpperCase()}</Text>

        <SEHomeCard mb={5} borderTopLeftRadius={'5px'}>
          <Text>
            {site.city}, {site.state}
          </Text>
          <Divider my={5} />
          <Text>{site.post_code}</Text>
        </SEHomeCard>
        {isLoading ? (
          <CenteredLoader text={`${t('Common.loading')}...`} my={4} />
        ) : (
          <Box onSubmit={handleFormSubmit(handleSubmit)} as={'form'} data-testid="vpp-form">
            <Text>{t('VPP.my energy provider').toUpperCase()}</Text>

            <Box position={'relative'}>
              <Controller
                control={control}
                name="energyProvider"
                render={({ field }) => (
                  <FormControl data-testid="energy-provider-input" isInvalid={!!errors.energyProvider}>
                    <CustomSelect
                      {...field}
                      options={utilityOptions}
                      placeholder={`${t('Energy Rates.select energy provider')}...`}
                      isSearchable={false}
                    />
                    <FormErrorMessage data-testid="energy-provider-input-error" mt={1}>
                      {errors?.energyProvider?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </Box>

            <Text fontSize="sm" color={'customGrey.400'} mt={0}>
              {t('VPP.energy provider helper text')}
            </Text>
            <FormControl mt={3} isInvalid={!!errors.energyAccountNumber}>
              <Input
                {...register('energyAccountNumber')}
                borderRadius={0}
                height="50px"
                type="text"
                data-private
                autoComplete="off"
                data-testid="energy-account-input"
                background={backgroundColor}
                borderColor={'customGrey.400'}
                placeholder={`${t('VPP.energy account number')}...`}
              />
              <FormErrorMessage data-testid="energy-account-input-error" mt={1}>
                {errors?.energyAccountNumber?.message}
              </FormErrorMessage>
            </FormControl>
            <Text fontSize="sm" color={'customGrey.400'} mt={0}>
              {t('VPP.energy account number helper text')}
            </Text>
            <Center flexDir="column">
              <ArcButton
                type="submit"
                arcColor="#3DCD58"
                minWidth="80%"
                my={10}
                py={6}
                data-testid="submit-button"
                isLoading={isSubmitting}
              >
                {t('VPP.enrol in vpp')}
              </ArcButton>
            </Center>
          </Box>
        )}
      </Box>
    </SlidingAnimationPageBase>
  );
}
