import { useCallback, useEffect, useState } from 'react';
import { useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectSite } from '../../site/siteSlice';
import { getEnergyModeType, StandardMode, TemporaryMode } from './energy-modes-helpers';
import { useGetEnergyModesQuery, useUpdateEnergyModesMutation } from './energyModesApi';

export function useEnergyModeChange() {
  const { t } = useTranslation();
  const { site_id: siteId } = useSelector(selectSite);
  const toast = useToast({ isClosable: true });
  const [hasPendingValue, setHasPendingValue] = useState(false);
  const pollingInterval = hasPendingValue ? 3000 : 0;
  const { data: energyModesData, isError, isLoading } = useGetEnergyModesQuery(siteId, { pollingInterval });
  const [updateEnergyModes, { isLoading: isUpdateLoading }] = useUpdateEnergyModesMutation();
  const selectedType = getSelectedEnergyModeType();
  const selectedMode = getSelectedEnergyMode();

  // A re-render is required to update the polling interval when a pending value exists -- we can't use the state from
  // the query itself, as this creates a circular reference, so state tracks the pending value.
  useEffect(() => {
    if (!isLoading && !isError) {
      setHasPendingValue(!!(energyModesData && energyModesData?.pending));
    }
  }, [energyModesData, isLoading, isError]);

  function getSelectedEnergyMode() {
    if (!energyModesData) return 'LOW_POWER';
    if (energyModesData?.pending) return energyModesData.pending.energy_mode;

    return energyModesData.energy_mode;
  }

  function getSelectedEnergyModeType() {
    if (!energyModesData) return getEnergyModeType('LOW_POWER');
    if (energyModesData?.pending) return getEnergyModeType(energyModesData.pending.energy_mode);

    return getEnergyModeType(energyModesData.energy_mode);
  }

  const handleChangeEnergyMode = useCallback(async (mode: StandardMode | TemporaryMode) => {
    try {
      await updateEnergyModes({ siteId, energyMode: mode }).unwrap();
    } catch (e) {
      toast({
        status: 'error',
        title: t('Energy Modes.error setting mode', { energyMode: t(`Energy Modes.${mode}.title`) }),
      });
    }
  }, []);

  return {
    handleChangeEnergyMode,
    isPending: isUpdateLoading || hasPendingValue,
    selectedMode,
    selectedType,
    isError,
    isLoading,
  };
}
