import React from 'react';
import { Box, Text, useDisclosure } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { PrivacyIcon } from 'clipsal-cortex-icons/src/custom-icons';

import SEHomeCard from '../../../../common/components/SEHomeCard';
import SlidingAnimationPageBase from '../../../../common/components/SlidingAnimationPageBase';
import { IS_DEMO_LOGIN } from '../../../../common/constants';
import AccountMenuButton from '../../AccountMenuButton';
import PrivacyNoticeModal from '../privacy/PrivacyNoticeModal';
import { useParamBackUrl } from './../../../../common/hooks/use-param-back-url';
import ConsentManagementButton from './ConsentManagementButton';
import InstallerConsentToggle from './InstallerConsentToggle';

export function PrivacySettingsHome() {
  const navigate = useNavigate();
  const { isOpen: isPrivacyOpen, onOpen: onPrivacyOpen, onClose: onPrivacyClose } = useDisclosure();
  const { t } = useTranslation();
  const backURL = useParamBackUrl('../../dashboard?direction=back');

  return (
    <SlidingAnimationPageBase
      backURL={backURL}
      title={
        <>
          <PrivacyIcon w="20px" h="22px" mr={3} data-testid="privacy-icon" />

          {t('Account.privacy settings')}
        </>
      }
    >
      <Box mx={3}>
        <SEHomeCard mb={3} borderTopLeftRadius={'5px'} minHeight="80px">
          <InstallerConsentToggle />
        </SEHomeCard>

        <SEHomeCard mb={4} borderTopLeftRadius={'5px'} p={0}>
          <AccountMenuButton
            data-testid={'delete-account-button'}
            includeBottomDivider={false}
            onClick={() => navigate(`delete-account`)}
            isDisabled={IS_DEMO_LOGIN}
          >
            <Text fontSize={16} fontWeight="normal">
              {t('Account.delete my account')}
            </Text>
          </AccountMenuButton>
        </SEHomeCard>

        <SEHomeCard mb={4} borderTopLeftRadius={'5px'} p={0}>
          <AccountMenuButton data-testid={'privacy-notice-button'} includeBottomDivider={false} onClick={onPrivacyOpen}>
            <Text fontSize={16} fontWeight="normal">
              {t('Privacy Notice.privacy notice')}
            </Text>
          </AccountMenuButton>
        </SEHomeCard>

        <ConsentManagementButton />

        <PrivacyNoticeModal isOpen={isPrivacyOpen} onClose={onPrivacyClose} />
      </Box>
    </SlidingAnimationPageBase>
  );
}
