import React, { useMemo, useState } from 'react';
import {
  Box,
  Center,
  Divider,
  Flex,
  Heading,
  IconButton,
  Link,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { BsThreeDots } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import CenteredLoader from 'clipsal-cortex-ui/src/components/CenteredLoader';
import useOnMount from 'clipsal-cortex-utils/src/hooks/use-on-mount';

import ArcButton from '../../../common/components/ArcButton';
import OptionSelectAlertDrawer from '../../../common/components/OptionSelectAlertDrawer';
import SlidingAnimationPageBase from '../../../common/components/SlidingAnimationPageBase';
import { useParamBackUrl } from '../../../common/hooks/use-param-back-url';
import { selectInverters } from '../../site/siteSlice';
import PrivacyNoticeModal from '../settings/privacy/PrivacyNoticeModal';
import TermsOfUseModal from '../TermsOfUseModal';
import CustomVppIcon from './CustomVPPIcon';
import { useGetEligibleVppProgramsToJoin } from './helpers';
import { RespondToProgramOffer, useRespondToVppProgramOfferMutation } from './vppApi';

export function JoinProgram() {
  const [{ id: inverterId }] = useSelector(selectInverters);
  const textColor = useColorModeValue('customGrey.900', 'customGrey.300');
  const iconColor = useColorModeValue('primaryBranding.600', 'primaryBranding.200');
  const linkColor = useColorModeValue('schneiderSkyBlue.600', 'schneiderSkyBlue.200');
  const { isOpen: isTermsOpen, onOpen: onTermsOpen, onClose: onTermsClose } = useDisclosure();
  const { isOpen: isPrivacyOpen, onOpen: onPrivacyOpen, onClose: onPrivacyClose } = useDisclosure();
  const { isOpen: isOptionDrawerOpen, onOpen: onOpenOptionDrawer, onClose: onCloseOptionDrawer } = useDisclosure();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const toast = useToast({ isClosable: true });
  const { offers, isLoaded } = useGetEligibleVppProgramsToJoin(inverterId);
  const [selectedOffer, setSelectedOffer] = useState<RespondToProgramOffer>();
  const [respondToVppProgramOffer] = useRespondToVppProgramOfferMutation();
  const backURL = useParamBackUrl('../../../account/dashboard?direction=back');

  const offerOptions = useMemo(
    () => offers.map((offer) => ({ value: offer.program_mrid, label: offer.program_name })),
    [offers]
  );

  useOnMount(() => {
    if (offers.length && isLoaded) {
      setSelectedOffer(offers[0]);
    }
  }, [offers, isLoaded]);

  function handleSelectOptionDrawerOption(value: string) {
    const offer = offers.find((offer) => offer.program_mrid === value);
    if (offer) setSelectedOffer(offer);
    onCloseOptionDrawer();
  }

  async function handleSubmit() {
    if (!selectedOffer) return;
    try {
      const body: RespondToProgramOffer = {
        site_program_notification_mrid: selectedOffer.site_program_notification_mrid,
        program_mrid: selectedOffer.program_mrid,
        battery_mrid: selectedOffer.battery_mrid,
        is_accepted: true,
      };
      await respondToVppProgramOffer({ inverterId, body }).unwrap();
      navigate(`../home?direction=forward`);
    } catch (e) {
      console.error(e);
      toast({
        title: t('VPP.error joining program'),
        description: `${t('Common.please try again')} ${t('Common.if this persists contact support')}`,
        status: 'error',
        duration: 3000,
      });
    }
  }

  return (
    <SlidingAnimationPageBase mx={4} mb={4} backURL={backURL} title={t('VPP.title')}>
      <Box data-testid="join-vpp-program-screen">
        <Flex position="absolute" right={0}>
          <IconButton
            aria-label="vpp settings"
            variant="unstyled"
            height="auto"
            icon={<BsThreeDots size={26} />}
            data-testid="three-dots-btn"
            onClick={onOpenOptionDrawer}
            hidden={!isLoaded || !offers.length}
            right={0}
          />
        </Flex>
        <OptionSelectAlertDrawer
          isOpen={isOptionDrawerOpen}
          onClose={onCloseOptionDrawer}
          onSelectOption={handleSelectOptionDrawerOption}
          options={offerOptions}
          title={t('VPP.nav title')}
        />

        <CustomVppIcon iconColor={iconColor} />

        <Heading textAlign="center" my={2} lineHeight={1}>
          {t('VPP.select program title')}
        </Heading>
        <Text textAlign="center" color={textColor} mb={2}>
          {t('VPP.select program subtitle')}
        </Text>

        <Divider my={2} />

        {isLoaded ? (
          <>
            <Text>{t('VPP.join the vpp and earn')}</Text>
            <Text mb={2}>{t('VPP.your battery will help')}</Text>
            <Text fontWeight="bold">{t('VPP.benefits')}</Text>
            <Text>- {t('VPP.power the grid')}</Text>
            <Text mb={6}>- {t('VPP.earn during events')}</Text>
            <Center>
              <ArcButton
                arcColor="#3DCD58"
                minWidth="80%"
                mb={4}
                py={6}
                onClick={handleSubmit}
                isDisabled={!selectedOffer}
                data-testid="submit-button"
              >
                {t('VPP.join program')}
              </ArcButton>
            </Center>
            <Text mx={8} mb={6} textAlign="center">
              {t('VPP.by clicking join program')}{' '}
              <Link fontWeight={600} data-testid="privacy-notice-link" color={linkColor} onClick={onPrivacyOpen}>
                {t('Privacy Notice.privacy notice')}
              </Link>{' '}
              {t('Set Address.and')}{' '}
              <Link fontWeight={600} data-testid="terms-of-use-link" color={linkColor} onClick={onTermsOpen}>
                {t('Common.terms of use')}
              </Link>{' '}
              {t('VPP.for se home and vpp')}
            </Text>
            <PrivacyNoticeModal isOpen={isPrivacyOpen} onClose={onPrivacyClose} />
            <TermsOfUseModal isOpen={isTermsOpen} onClose={onTermsClose} />
          </>
        ) : (
          <CenteredLoader text={`${t('Common.loading')}...`} my={8} />
        )}
      </Box>
    </SlidingAnimationPageBase>
  );
}
