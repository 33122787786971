import { useMemo } from 'react';

import { VPPProgramStatus } from 'clipsal-cortex-types/src/api/api-saturn-vpp';

import { RespondToProgramOffer, useGetVppProgramOffers, useGetVppProgramsStatus } from './vppApi';

export function getProgramByStatus(
  programsStatus: VPPProgramStatus[],
  status: 'Eligible' | 'Pending' | 'Ineligible' | 'Enrolled'
) {
  return programsStatus.find((programStatus) => programStatus.equipment_program?.status === status);
}

type EligibleVPPProgram = Required<Omit<RespondToProgramOffer, 'is_accepted'>>;

// This hook provides the list of programs a user is eligible to join for a given inverter.
// We must consolidate 2 sources of data (current program status and program offers) --
// -- in order to have all the data required to respond to the VPP program offer.
export function useGetEligibleVppProgramsToJoin(inverterId: number) {
  const {
    data: programsStatus,
    isLoading: isProgramStatusLoading,
    isError: isProgramStatusError,
  } = useGetVppProgramsStatus(inverterId);
  const {
    programOffers,
    isLoading: isProgramOffersLoading,
    isError: isProgramOffersError,
  } = useGetVppProgramOffers(inverterId);

  const isError = isProgramStatusError || isProgramOffersError;
  const isLoaded = !isProgramStatusLoading && !isProgramOffersLoading;

  const vppProgramOffers: EligibleVPPProgram[] = useMemo(() => {
    if (isLoaded && !isError) {
      const programs = programsStatus.filter((programStatus) => programStatus.equipment_program?.status === 'Eligible');
      return (
        programs
          // We need this filter to consolidate the program offer notification with the inverter program status --
          // -- otherwise we are unable to respond to the program offer.
          .filter((program) =>
            programOffers?.find(
              (programOffer) => programOffer.program.m_rid === program.equipment_program?.program.m_rid
            )
          )
          .map((program) => {
            return {
              site_program_notification_mrid:
                programOffers?.find(
                  (programOffer) => programOffer.program.m_rid === program.equipment_program?.program.m_rid
                )?.m_rid ?? '',
              program_mrid: program.equipment_program?.program.m_rid ?? '',
              program_name: program.equipment_program?.program.name ?? '',
              battery_mrid: program.equipment.m_rid,
            };
          })
      );
    }
    return [];
  }, [isLoaded, isError, programsStatus, programOffers]);

  return useMemo(() => {
    return {
      offers: vppProgramOffers,
      isLoaded,
      isError,
    };
  }, [vppProgramOffers, isLoaded, isError]);
}
