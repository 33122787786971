import React, { useMemo } from 'react';
import { Text } from '@chakra-ui/react';
import { toZonedTime } from 'date-fns-tz';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { formatTo12HourTime } from 'clipsal-cortex-utils/src/formatting/formatting';
import useAppVisibility from 'clipsal-cortex-utils/src/hooks/use-app-visibility';

import { IS_DEMO_LOGIN } from '../../../common/constants';
import { useSiteDeviceCheck } from '../../../common/hooks/use-site-device-check';
import { getTimeFormat } from '../../account/settings/settings-helpers';
import { useGetInverterLiveDataQuery } from '../../site/live-data/liveDataApi';
import { selectSite } from '../../site/siteSlice';

// This component displays the last updated time of Saturn live data if it is available.
export default function SaturnLastUpdated() {
  const { site_id: siteId, timezone } = useSelector(selectSite);
  const { siteHasSaturnInverter } = useSiteDeviceCheck();
  const isAppVisible = useAppVisibility() || IS_DEMO_LOGIN; // No need to skip API calls in demo mode
  const { data: liveInverterData } = useGetInverterLiveDataQuery(siteId, {
    pollingInterval: 5000,
    skip: !siteHasSaturnInverter || !isAppVisible,
  });
  const { t } = useTranslation();

  const lastUpdatedDate = useMemo(
    () => (liveInverterData?.last_updated ? toZonedTime(liveInverterData.last_updated, timezone) : undefined),
    [liveInverterData?.last_updated]
  );

  const lastUpdatedString = useMemo(
    () => (lastUpdatedDate ? formatLastUpdated(lastUpdatedDate) : ''),
    [lastUpdatedDate]
  );

  return (
    <Text
      fontSize="0.8rem"
      hidden={!lastUpdatedDate}
      textAlign="center"
      color="customGrey.400"
      data-testid="last-updated"
    >
      [{t('Common.last updated')}: {lastUpdatedString}]
    </Text>
  );
}

function formatLastUpdated(date: Date): string {
  const timeFormat = getTimeFormat();
  if (timeFormat === '24HR') return date.toLocaleTimeString();
  else return formatTo12HourTime(date);
}
