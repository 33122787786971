export const TESLA_CLIENT_ID = '7b4387c3-3c38-49a6-8d0e-b8425b32f4d0'; // SE Home
export const TESLA_SCOPES = [
  'openid',
  'email',
  'offline_access',
  'user_data',
  'vehicle_device_data',
  'vehicle_cmds',
  'vehicle_charging_cmds',
  'energy_device_data',
  'energy_cmds',
];
