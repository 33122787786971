import React from 'react';
import { Box, Center, Heading, Text, useColorModeValue, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import ArcButton from '../../../common/components/ArcButton';
import SlidingAnimationPageBase from '../../../common/components/SlidingAnimationPageBase';
import { CircleIcon, GridIcon } from '../../../styles/custom-icons';
import { selectSite } from '../../site/siteSlice';

export function EnergyModesOnboardingFinish() {
  const site = useSelector(selectSite);
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const { t } = useTranslation();
  const iconColor = useColorModeValue('primaryBranding.600', 'primaryBranding.200');
  const paramBackURL = search.get('backURL');
  const backURL = `../home`;

  return (
    <SlidingAnimationPageBase
      title={t('Energy Modes.energy modes')}
      includeTopNav
      px={4}
      backURL={paramBackURL ?? backURL}
    >
      <VStack align={'initial'}>
        <Center position="relative" mb={1} flexDirection="column" textAlign="center">
          <Box w="80px" position={'absolute'}>
            <GridIcon w="100%" h="100%" color={iconColor} data-testid={`grid-icon`} />
          </Box>
          <CircleIcon h="60%" w="60%" color={iconColor} data-testid="circle-icon" zIndex={3} />
        </Center>
        <Heading textAlign="center" fontSize={24}>
          {t('Set Up Hardware.setup successful')}
        </Heading>
        <Text textAlign="center">{t('Energy Modes.energy mode setup complete')}</Text>

        <ArcButton
          arcColor="#3DCD58"
          minWidth="280px"
          py={6}
          data-testid="continue-btn"
          onClick={() => navigate(`/sites/${site.site_id}/home`)}
          mt={8}
        >
          {t('Common.continue')}
        </ArcButton>
      </VStack>
    </SlidingAnimationPageBase>
  );
}

export default EnergyModesOnboardingFinish;
