import React from 'react';
import { Box, Button, Center, Heading, useColorModeValue, useDisclosure, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import AlertDialogModal from 'clipsal-cortex-ui/src/components/AlertDialogModal';

import ArcButton from '../../../common/components/ArcButton';
import SlidingAnimationPageBase from '../../../common/components/SlidingAnimationPageBase';
import { CircleIcon, GridIcon } from '../../../styles/custom-icons';
import { selectSite } from '../../site/siteSlice';

export function EnergyModesOnboardingStart() {
  const site = useSelector(selectSite);
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const { t } = useTranslation();
  const linkButtonColor = useColorModeValue('schneiderSkyBlue.600', 'schneiderSkyBlue.200');
  const { isOpen: isSkipAlertOpen, onOpen: onOpenSkipAlert, onClose: onCloseSkipAlert } = useDisclosure();

  const paramBackURL = search.get('backURL');
  const backURL = `../home`;

  const handleOnClick = async () => {
    if (site.isOnboarding) {
      navigate(`${backURL}?backURL=../start`);
    } else {
      navigate(backURL);
    }
  };

  return (
    <SlidingAnimationPageBase
      title={t('Energy Modes.energy modes')}
      includeTopNav
      px={4}
      backURL={paramBackURL ?? backURL}
    >
      <VStack align={'initial'}>
        <Center position="relative" mb={1} flexDirection="column" textAlign="center">
          <Box w="80px" position={'absolute'}>
            <GridIcon w="100%" h="100%" color="#9FA0A4" data-testid={`grid-icon`} />
          </Box>
          <CircleIcon h="60%" w="60%" color="#9FA0A4" data-testid="circle-icon" zIndex={3} />
        </Center>
        <Heading textAlign="center" fontSize={24}>
          {t('Energy Modes.set your energy mode')}
        </Heading>
        <ArcButton arcColor="#3DCD58" minWidth="280px" py={6} data-testid="continue-btn" onClick={handleOnClick} mt={8}>
          {t('Common.continue')}
        </ArcButton>
        <Button
          variant="link"
          px={0}
          minWidth={'fit-content'}
          w="fit-content"
          fontWeight={400}
          fontSize={15}
          color={linkButtonColor}
          onClick={onOpenSkipAlert}
          data-testid="skip-btn"
          mx="auto"
          my={2}
        >
          {t('Common.skip').toUpperCase()}
        </Button>
        <AlertDialogModal
          isOpen={isSkipAlertOpen}
          onClose={onCloseSkipAlert}
          header={t('Common.skip this step?')}
          subHeader={`${t('Common.are you sure skip?')} ${t('Common.set it up later')}`}
          confirmButtonName={t('Common.skip')}
          cancelButtonName={t('Common.cancel')}
          onConfirm={() => navigate(`/sites/${site.site_id}/home`)}
        />
      </VStack>
    </SlidingAnimationPageBase>
  );
}

export default EnergyModesOnboardingStart;
