import React, { useState } from 'react';
import { DeleteIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Select,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { useFieldArray, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { CHARGE_PERIODS } from 'clipsal-cortex-types/src/api/api-tariffs-v2';

import ArcButton from '../../../common/components/ArcButton';
import SlidingAnimationPageBase from '../../../common/components/SlidingAnimationPageBase';
import MinimumDeliveryCharge from '../MinimumDeliveryCharge';
import SeasonMultiToggleSwitch from '../SeasonMultiToggleSwitch';
import { MIN_TARIFF_PAGE_HEIGHT, useTranslatedCommonInputProps } from '../tariff-constants';
import { useTariffFormContext } from '../useTariffFormContext';
import { useParamBackUrl } from './../../../common/hooks/use-param-back-url';
import { getDefaultTieredTariffRates } from './tiered-tariff-helpers';

export const TieredTariffRates = () => {
  const { errors, register, control, handleSubmit } = useTariffFormContext();
  const { tariff } = useWatch({ control });
  const seasons = tariff?.seasons || [];
  const [selectedSeasonIndex, setSelectedSeasonIndex] = useState(0);
  const navigate = useNavigate();
  const backURL = useParamBackUrl('../../select-season?direction=back');
  const { t } = useTranslation();
  const COMMON_INPUT_PROPS = useTranslatedCommonInputProps();

  console.log(errors);

  return (
    <SlidingAnimationPageBase
      title={t('Energy Rates.manual energy rates set up')}
      includeTopNav
      px={4}
      backURL={backURL}
      data-testid="tiered-tariff-rates"
    >
      <VStack align={'initial'} minHeight={MIN_TARIFF_PAGE_HEIGHT}>
        <Text>
          {t('Energy Rates.you chose tiered rate')} {t('Energy Rates.enter the price for each tier')}
        </Text>

        <Box mt={3}>
          <MinimumDeliveryCharge />
        </Box>

        <SeasonMultiToggleSwitch
          value={selectedSeasonIndex}
          onChange={(newIndex) => setSelectedSeasonIndex(newIndex)}
          containerProps={{ mt: 4 }}
        />

        {/* Without mapping, uncontrolled components are not re-rendered by react */}
        {seasons.map((season, seasonIndex) => {
          return (
            <Box key={season.seasonIndex} mb={4} display={seasonIndex === selectedSeasonIndex ? undefined : 'none'}>
              <TieredTariffImportRates seasonIndex={seasonIndex} />

              <Box mt={6}>
                <Text lineHeight={'19px'}>{t('Energy Rates.export rate').toUpperCase()}</Text>
                <Divider bg={'#C6C6C6'} mb={4} mt={1} />
                <Text fontWeight={'bold'} lineHeight={'19px'} mb={3}>
                  {t('Energy Rates.enter price per kwh exported').toUpperCase()}
                </Text>
                <FormControl
                  isInvalid={!!errors?.tariff?.rates?.[seasonIndex]?.export?.[0]?.rateBands?.[0]?.rate?.message}
                  data-testid={`season-${seasonIndex}-export-rate`}
                >
                  <InputGroup>
                    <InputLeftElement pointerEvents="none" color="gray.300" h={12}>
                      $
                    </InputLeftElement>
                    <Input
                      {...COMMON_INPUT_PROPS}
                      {...register(`tariff.rates.${seasonIndex}.export.0.rateBands.0.rate`, { valueAsNumber: true })}
                      pr={16}
                    />
                    <InputRightElement pointerEvents="none" color="gray.300" h={12} w={16}>
                      / kWh
                    </InputRightElement>
                  </InputGroup>
                  <FormErrorMessage>
                    {errors?.tariff?.rates?.[seasonIndex]?.export?.[0]?.rateBands?.[0]?.rate?.message}
                  </FormErrorMessage>
                </FormControl>
              </Box>
            </Box>
          );
        })}

        <Center mt="auto" mb={6} flexDir={'column'}>
          <ArcButton
            arcColor="#3DCD58"
            minWidth="280px"
            py={6}
            onClick={() => {
              handleSubmit(
                () => {
                  // using `handleSubmit` triggers validation and activates revalidation modes
                  // using `trigger` does not trigger revalidation
                  // Reaching here means form is valid. Thus, go to confirm page
                  navigate('../confirm');
                },
                (errors) => {
                  // Reaching here means form is invalid. Take user to the first tab with errors
                  const firstIndexWithError = seasons.findIndex((_, index) => errors?.tariff?.rates?.[index]);
                  if (firstIndexWithError >= 0) setSelectedSeasonIndex(firstIndexWithError);
                }
              )();
            }}
            data-testid="continue-button"
          >
            {t('Common.continue')}
          </ArcButton>
        </Center>
      </VStack>
    </SlidingAnimationPageBase>
  );
};

function TieredTariffImportRates({ seasonIndex }: { seasonIndex: number }) {
  const { errors, register, control, getValues } = useTariffFormContext();
  const {
    fields: rateBands,
    append,
    remove,
    update,
  } = useFieldArray({ name: `tariff.rates.${seasonIndex}.import.0.rateBands`, control }) || [];
  const importRate = useWatch({ control, name: `tariff.rates.${seasonIndex}.import.0` });
  const chargePeriod = importRate?.chargePeriod || 'DAILY';
  const { t } = useTranslation();
  const COMMON_INPUT_PROPS = useTranslatedCommonInputProps();

  const getImportRateBandsHeader = (rateBandIndex: number, totalRateBands: number) => {
    if (rateBandIndex === 0) {
      return {
        usageHeader: `${t('Energy Rates.tier')} ${rateBandIndex + 1} - ${t('Energy Rates.baseline allowance')}`,
        priceHeader: `${t('Energy Rates.tier')} ${rateBandIndex + 1} - 
        ${t('Energy Rates.baseline allowance price per kwh')}`,
      };
    } else if (rateBandIndex === totalRateBands - 1) {
      return {
        usageHeader: t('Energy Rates.high usage tier'),
        priceHeader: t('Energy Rates.price per kwh for high usage'),
      };
    } else {
      return {
        usageHeader: t('Energy Rates.tier allowance', { number: rateBandIndex + 1 }),
        priceHeader: t('Energy Rates.price per kwh for tier', {
          number: rateBandIndex + 1,
        }),
      };
    }
  };

  const getFinalTierConsumptionUpperLimit = () => {
    const secondLastRateBand = importRate?.rateBands?.[rateBands.length - 2];
    return `> ${secondLastRateBand?.consumptionUpperLimit || 0}`;
  };

  const optionStyles = useColorModeValue(
    { background: 'white', color: 'black' },
    { background: '#27282A', color: 'white' }
  );

  return (
    <Box mt={3}>
      <Flex justify="space-between" mt={4}>
        <Text lineHeight={'19px'}>{t('Energy Rates.import rate').toUpperCase()}</Text>
        <Button
          variant={'link'}
          fontWeight={400}
          colorScheme={'schneiderSkyBlue'}
          onClick={() => {
            // update consumptionLimit for old high rateband from null to NaN
            // NaN ensures the value is filled in by user
            const oldHighRateBandIndex = rateBands.length - 1;
            const oldHighRateBand = getValues().tariff.rates[seasonIndex].import[0].rateBands[oldHighRateBandIndex];
            update(oldHighRateBandIndex, { ...oldHighRateBand, consumptionUpperLimit: NaN });

            const defaultImportRateBand = getDefaultTieredTariffRates(0).import[0].rateBands[0];

            // Add new high rateband and index to the end
            const newHighRateBandSequenceNumber = rateBands.length + 1;
            append({
              ...defaultImportRateBand,
              sequenceNumber: newHighRateBandSequenceNumber,
              consumptionUpperLimit: null,
            });
          }}
          data-testid={`add-rateBands-${seasonIndex}-button`}
        >
          {t('Common.add')}
        </Button>
      </Flex>

      <Divider bg={'#C6C6C6'} mb={4} mt={1} />

      <Box mb={4}>
        <Text fontWeight={'bold'} lineHeight={'19px'} mb={3}>
          {t('Energy Rates.charge period')}
        </Text>
        <FormControl isInvalid={!!errors?.tariff?.rates?.[seasonIndex]?.import?.[0]?.chargePeriod?.message}>
          <Select
            data-testid={`import-rate-${seasonIndex}-charge-period`}
            size="lg"
            {...{
              borderRadius: 0,
              fontSize: '16px',
              borderColor: 'customGrey.400',
              _dark: { background: 'customGrey.900' },
            }}
            {...register(`tariff.rates.${seasonIndex}.import.0.chargePeriod`)}
          >
            {CHARGE_PERIODS.map((option) => (
              <option key={option} value={option} style={{ ...optionStyles, fontSize: '14px' }}>
                {t(`Energy Rates.labels.${option}`)}
              </option>
            ))}
          </Select>
          <FormErrorMessage>
            {errors?.tariff?.rates?.[seasonIndex]?.import?.[0]?.chargePeriod?.message}
          </FormErrorMessage>
        </FormControl>
      </Box>

      <Divider bg={'#C6C6C6'} mb={4} mt={1} />
      {rateBands.map((field, rateBandIndex, arr) => {
        const { usageHeader, priceHeader } = getImportRateBandsHeader(rateBandIndex, arr.length);
        const isLastIndex = rateBandIndex === arr.length - 1;
        const isFirstIndex = rateBandIndex === 0;
        // Do not show delete button for first and last rateband
        const showDeleteButton = !isLastIndex && !isFirstIndex;
        return (
          <Box key={field.id}>
            <Box>
              <Flex justify="space-between" mt={4}>
                <Text fontWeight={'bold'} lineHeight={'19px'} mb={3}>
                  {usageHeader}
                </Text>
                {showDeleteButton && (
                  <IconButton
                    aria-label="delete-rateBands"
                    variant={'ghost'}
                    minH={'fit-content'}
                    minW={'fit-content'}
                    h={7}
                    w={7}
                    borderRadius={50}
                    ml={4}
                    onClick={() => remove(rateBandIndex)}
                    data-testid={`delete-rateBands-${rateBandIndex}`}
                  >
                    <DeleteIcon color="red.500" />
                  </IconButton>
                )}
              </Flex>
              <FormControl
                isInvalid={
                  !!errors?.tariff?.rates?.[seasonIndex]?.import?.[0]?.rateBands?.[rateBandIndex]?.consumptionUpperLimit
                    ?.message
                }
                data-testid={`season-${seasonIndex}-import-tier-${rateBandIndex + 1}-kwh`}
              >
                <InputGroup>
                  <Input
                    {...COMMON_INPUT_PROPS}
                    placeholder={t('Energy Rates.enter kwh')}
                    {...(isLastIndex
                      ? { disabled: true, value: getFinalTierConsumptionUpperLimit(), type: 'text' }
                      : register(
                          `tariff.rates.${seasonIndex}.import.0.rateBands.${rateBandIndex}.consumptionUpperLimit`,
                          {
                            valueAsNumber: true,
                          }
                        ))}
                    pr={120}
                  />
                  <InputRightElement pointerEvents="none" color="gray.300" h={12} w={120}>
                    kWh / ${t(`Energy Rates.units.${chargePeriod}`)}
                  </InputRightElement>
                </InputGroup>

                <FormErrorMessage>
                  {
                    errors?.tariff?.rates?.[seasonIndex]?.import?.[0]?.rateBands?.[rateBandIndex]?.consumptionUpperLimit
                      ?.message
                  }
                </FormErrorMessage>
              </FormControl>
            </Box>

            <Box>
              <Text fontWeight={'bold'} lineHeight={'19px'} my={3}>
                {priceHeader}
              </Text>
              <FormControl
                isInvalid={
                  !!errors?.tariff?.rates?.[seasonIndex]?.import?.[0]?.rateBands?.[rateBandIndex]?.rate?.message
                }
                data-testid={`season-${seasonIndex}-import-tier-${rateBandIndex + 1}-price`}
              >
                <InputGroup>
                  <InputLeftElement pointerEvents="none" color="gray.300" h={12}>
                    $
                  </InputLeftElement>
                  <Input
                    {...COMMON_INPUT_PROPS}
                    {...register(`tariff.rates.${seasonIndex}.import.0.rateBands.${rateBandIndex}.rate`, {
                      valueAsNumber: true,
                    })}
                    pr={16}
                  />
                  <InputRightElement pointerEvents="none" color="gray.300" h={12} w={16}>
                    / kWh
                  </InputRightElement>
                </InputGroup>
                <FormErrorMessage>
                  {errors?.tariff?.rates?.[seasonIndex]?.import?.[0]?.rateBands?.[rateBandIndex]?.rate?.message}
                </FormErrorMessage>
              </FormControl>
            </Box>

            {!isLastIndex && <Divider bg={'#C6C6C6'} my={6} />}
          </Box>
        );
      })}
    </Box>
  );
}
