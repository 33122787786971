import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

import { MANUFACTURER_ID_SATURN } from '../../../features/devices/devices-helper';
import { selectInverters, selectSite } from '../../../features/site/siteSlice';

export function VPPRoutes() {
  const site = useSelector(selectSite);

  // All VPP features required a Saturn inverter, if a user somehow ends up here without a Saturn inverter --
  // -- we redirect them home
  const saturnInverter = useSelector(selectInverters).find((device) =>
    [MANUFACTURER_ID_SATURN].includes(device.manufacturer_id)
  );
  if (!saturnInverter?.id) {
    console.error('Site doesnt have the required hardware for VPP');
    return <Navigate to={`/site/${site.site_id}/home`} />;
  }
  return <Outlet />;
}
