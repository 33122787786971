import { MANUFACTURER_ID_AYLA } from '../../features/devices/devices-helper';
import { useGetSwitchesByManufacturerQuery } from '../../features/devices/switches/switchApi';
import { useSiteDeviceCheck } from './use-site-device-check';

type HardwareConfigurationType = 'default' | 'sense' | 'matter' | 'none';

export function useSiteHardwareConfigurationType(skip = false): {
  hardwareConfigurationType: HardwareConfigurationType;
  isLoading: boolean;
} {
  const { data: siteAylaSwitches, isLoading } = useGetSwitchesByManufacturerQuery([MANUFACTURER_ID_AYLA], skip);
  const { siteHasSaturnInverter, siteHasSenseMeter } = useSiteDeviceCheck();
  const siteHasAylaSwitches = siteAylaSwitches?.length;

  let hardwareConfigurationType: HardwareConfigurationType = 'none';

  if (siteHasSenseMeter && !siteHasSaturnInverter) {
    hardwareConfigurationType = 'sense';
  } else if (siteHasSaturnInverter) {
    hardwareConfigurationType = 'default';
  } else if (siteHasAylaSwitches) {
    hardwareConfigurationType = 'matter';
  }

  return {
    hardwareConfigurationType,
    isLoading,
  };
}
