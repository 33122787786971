import { useSelector } from 'react-redux';

import {
  MANUFACTURER_ID_SATURN,
  MANUFACTURER_ID_SE_SEM,
  MANUFACTURER_ID_SEM,
} from '../../features/devices/devices-helper';
import { selectBatteries, selectInverters, selectMeters } from '../../features/site/siteSlice';

export function useSiteDeviceCheck(): {
  siteHasSenseMeter: boolean;
  siteHasSaturnInverter: boolean;
  siteHasBattery: boolean;
} {
  const siteInverters = useSelector(selectInverters);
  const siteMeters = useSelector(selectMeters);
  const siteHasBattery = useSelector(selectBatteries).length > 0;
  const siteHasSenseMeter = siteMeters.some((meter) =>
    [MANUFACTURER_ID_SEM, MANUFACTURER_ID_SE_SEM].includes(meter.manufacturer_id)
  );
  const siteHasSaturnInverter = siteInverters.some((inverter) =>
    [MANUFACTURER_ID_SATURN].includes(inverter.manufacturer_id)
  );

  return {
    siteHasSenseMeter,
    siteHasSaturnInverter,
    siteHasBattery,
  };
}
