import React, { useEffect } from 'react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { Center, Flex, Text, useColorModeValue, useDisclosure, useToast } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import AlertDialogModal from 'clipsal-cortex-ui/src/components/AlertDialogModal';
import CenteredLoader from 'clipsal-cortex-ui/src/components/CenteredLoader';
import { useViewportType } from 'clipsal-cortex-utils/src/hooks/use-viewport-type';

import { useReduxDispatch } from '../../../app/store';
import ArcButton from '../../../common/components/ArcButton';
import SlidingAnimationPageBase from '../../../common/components/SlidingAnimationPageBase';
import { useParamBackUrl } from '../../../common/hooks/use-param-back-url';
import { deactivateEvCharger, fetchEvChargerDetails, selectEvChargerData } from './evChargerSlice';

/* istanbul ignore next -- @preserve */
export const DeviceDetails = () => {
  const { chargerId, isDeactivating, hasFetchedEvChargerDetails, description, firmware, model, output, sn } =
    useSelector(selectEvChargerData);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useReduxDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const { isDesktopViewport } = useViewportType();
  const { wifiButtonBgColor } = useColorModeValue({ wifiButtonBgColor: '#E7E6E6' }, { wifiButtonBgColor: '#363E40' });
  const backURL = useParamBackUrl('../ev_charger_status?direction=back');

  useEffect(() => {
    // only fetch device details if not fetched already
    if (!hasFetchedEvChargerDetails && chargerId) {
      dispatch(fetchEvChargerDetails(chargerId));
    }
  }, [hasFetchedEvChargerDetails, chargerId]);

  return (
    <SlidingAnimationPageBase title="Schneider Charge" backURL={backURL} customNavigationDirection="forward">
      {!hasFetchedEvChargerDetails ? (
        <CenteredLoader />
      ) : (
        <Flex direction={'column'} w="100%" data-testid="device-details-screen">
          <Flex direction={'column'} p={6} mx={isDesktopViewport ? 4 : 0} borderRadius={isDesktopViewport ? 4 : 0}>
            <Flex direction={'column'} data-testid="device-sn">
              <Text fontWeight={'semibold'}>Serial Number</Text>
              <Text>{sn}</Text>
            </Flex>
            <Flex direction={'column'} mt={6} data-testid="device-model">
              <Text fontWeight={'semibold'}>Model Number</Text>
              <Text>{model}</Text>
            </Flex>
            <Flex direction={'column'} mt={6} data-testid="device-desc">
              <Text fontWeight={'semibold'}>Description</Text>
              <Text>{description}</Text>
            </Flex>
            <Flex direction={'column'} mt={6} data-testid="device-output">
              <Text fontWeight={'semibold'}>Output</Text>
              <Text>{output}</Text>
            </Flex>
            <Flex direction={'column'} mt={6} data-testid="device-manufacturer">
              <Text fontWeight={'semibold'}>Manufacturer</Text>
              <Text>Schneider Electric</Text>
            </Flex>
            <Flex direction={'column'} mt={6} data-testid="device-firmware">
              <Text fontWeight={'semibold'}>Firmware</Text>
              <Text>{firmware}</Text>
            </Flex>
          </Flex>

          <Center
            data-testid="wifi-config-button"
            justifyContent={'space-between'}
            mt={6}
            py={6}
            px={4}
            bgColor={wifiButtonBgColor}
            onClick={() => navigate(`../../wifi/home`)}
          >
            <Text>Wi-Fi Configuration</Text>
            <ChevronRightIcon color={'customGrey.400'} h={6} w={6} />
          </Center>

          <ArcButton
            arcColor="#3DCD58"
            data-testid="device-remove-button"
            minWidth="280px"
            mx="auto"
            mt={10}
            py={6}
            onClick={onOpen}
            isLoading={isDeactivating}
          >
            Remove Device
          </ArcButton>

          <AlertDialogModal
            {...{ isOpen, onClose }}
            header="Are you sure?"
            subHeader="Your EVSE will be removed from the system."
            confirmButtonName="Remove"
            onConfirm={async () => {
              try {
                await dispatch(deactivateEvCharger(chargerId));
                navigate('../ev_charger_status');
              } catch (error) {
                toast({
                  title: 'Something went wrong while deactivating the EVSE Charger',
                  status: 'error',
                  duration: 5000,
                });
              }
            }}
            dialogContentTestId="device-remove-dialog"
            cancelButtonTestId="device-dialog-cancel-button"
            confirmButtonTestId="device-dialog-remove-button"
          />
        </Flex>
      )}
    </SlidingAnimationPageBase>
  );
};
