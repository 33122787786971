import {
  VPPEquipmentEvents,
  VPPProgramOffer,
  VPPProgramStatus,
  VPPUtility,
  VPPUtilityStatus,
} from 'clipsal-cortex-types/src/api/api-saturn-vpp';

import { baseApi } from '../../../app/services/baseApi';

export type ProvideUtilityStatus = {
  utility_account_number: string;
  utility_mrid: string;
};

export type RespondToProgramOffer = {
  site_program_notification_mrid: string;
  program_mrid: string;
  program_name?: string;
  battery_mrid: string;
  is_accepted?: boolean;
};

export const vppApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    // Returns any current VPP program details for each 'equipment' part of the Saturn inverter
    getVppProgramsStatus: build.query<VPPProgramStatus[], number>({
      query: (inverterId) => `/v1/site_devices/${inverterId}/vpp_program_status`,
      providesTags: ['VPPProgramsStatus'],
    }),
    // Returns the utility providers that the user can select from for VPP enrolment
    getVppUtilities: build.query<VPPUtility[], number>({
      query: (inverterId) => `/v1/site_devices/${inverterId}/vpp_utilities`,
      providesTags: ['VPPUtilities'],
    }),
    // Returns the utility details saved applicable for VPP enrolment
    getVppUtilityStatus: build.query<VPPUtilityStatus[], number>({
      query: (inverterId) => `/v1/site_devices/${inverterId}/vpp_utility_status`,
      providesTags: ['VPPUtilityStatus'],
    }),
    // Saves the utility details to be applicable for VPP enrolment
    createVppUtilityStatus: build.mutation<VPPUtilityStatus, { inverterId: number; body: ProvideUtilityStatus }>({
      query: ({ inverterId, body }) => {
        return {
          url: `/v1/site_devices/${inverterId}/vpp_utility_status`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['VPPUtilityStatus'],
    }),
    // Returns any VPP program offers that the user can accept
    getVppProgramOffers: build.query<VPPProgramOffer[], number>({
      query: (inverterId) => `/v1/site_devices/${inverterId}/vpp_program_offers`,
      providesTags: ['VPPProgramOffers'],
    }),
    respondToVppProgramOffer: build.mutation<void, { inverterId: number; body: RespondToProgramOffer }>({
      query: ({ inverterId, body }) => {
        return {
          url: `/v1/site_devices/${inverterId}/vpp_program_offers`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['VPPProgramOffers', 'VPPProgramsStatus'],
    }),
    // Returns all current/scheduled VPP events for each 'equipment' part of the Saturn inverter
    getVppEvents: build.query<VPPEquipmentEvents[], number>({
      query: (inverterId) => `/v1/site_devices/${inverterId}/vpp_events`,
      providesTags: ['VPPEvents'],
    }),
  }),
});

export const {
  useGetVppProgramsStatusQuery,
  useGetVppProgramOffersQuery,
  useGetVppUtilitiesQuery,
  useGetVppUtilityStatusQuery,
  useGetVppEventsQuery,
  useCreateVppUtilityStatusMutation,
  useRespondToVppProgramOfferMutation,
} = vppApi;

export function useGetVppProgramOffers(inverterId: number) {
  const { data: programOffers, ...rest } = useGetVppProgramOffersQuery(inverterId);
  return { programOffers: programOffers ?? [], ...rest };
}

export function useGetVppUtilities(inverterId: number) {
  const { data: utilities, ...rest } = useGetVppUtilitiesQuery(inverterId);
  return { utilities: utilities ?? [], ...rest };
}

export function useGetVppProgramsStatus(inverterId?: number) {
  const { data, ...rest } = useGetVppProgramsStatusQuery(inverterId!, { skip: !inverterId });
  return { data: data ?? [], ...rest };
}

export function useGetVppUtilityStatus(inverterId: number) {
  const { data, ...rest } = useGetVppUtilityStatusQuery(inverterId);
  return { data: data ?? [], ...rest };
}

export function useGetVppEvents(inverterId: number) {
  const { data, ...rest } = useGetVppEventsQuery(inverterId);
  return { data: data ?? [], ...rest };
}
