import { ComponentWithAs, IconProps } from '@chakra-ui/react';

import {
  AirConIcon,
  BackupPanelIcon,
  CoffeeMakerIcon,
  DishwasherIcon,
  GarageDoorOpenerIcon,
  GarageIcon,
  GarbageDisposalIcon,
  HotWaterHeaterIcon,
  KitchenIcon,
  LightIcon,
  MicrowaveIcon,
  OtherDeviceIcon,
  OutletIcon,
  OvenIcon,
  PoolPumpIcon,
  RefrigeratorIcon,
  SEHomeEVIcon,
  ShedIcon,
  SolarHotWaterIcon,
  SpaIcon,
  StoveIcon,
  SubPanelIcon,
  UnderFloorHeatingIcon,
  WashingMachineIcon,
} from '../../styles/custom-icons';

export const CIRCUIT_TYPE_TO_UI_CONFIG: Record<
  string,
  { displayName: string; icon: ComponentWithAs<'svg', IconProps> }
> = {
  load_air_conditioner: {
    icon: AirConIcon,
    displayName: 'Air Conditioner',
  },
  load_powerpoint: { icon: OutletIcon, displayName: 'Outlet' },
  load_pool: { icon: PoolPumpIcon, displayName: 'Pool Pump' },
  load_hot_water: { icon: HotWaterHeaterIcon, displayName: 'Hot Water Heater' },
  load_lighting: { icon: LightIcon, displayName: 'Lighting' },
  load_oven: { icon: OvenIcon, displayName: 'Oven' },
  load_stove: { icon: StoveIcon, displayName: 'Stove' },
  load_ev: { icon: SEHomeEVIcon, displayName: 'EV' },
  load_garage: { icon: GarageIcon, displayName: 'Garage' },
  load_kitchen: { icon: KitchenIcon, displayName: 'Kitchen' },
  load_laundry: { icon: WashingMachineIcon, displayName: 'Laundry Room' },
  load_hot_water_solar: { icon: SolarHotWaterIcon, displayName: 'Solar Water Heater' },
  load_refrigerator: { icon: RefrigeratorIcon, displayName: 'Fridge' },
  load_subboard: { icon: SubPanelIcon, displayName: 'Sub Panel' },
  load_shed: { icon: ShedIcon, displayName: 'Shed' },
  load_underfloor_heating: { icon: UnderFloorHeatingIcon, displayName: 'Under Floor Heating' },
  load_spa: { icon: SpaIcon, displayName: 'Hot Tub' },
  load_other: { icon: OtherDeviceIcon, displayName: 'Other' },
  load_coffee_maker: { icon: CoffeeMakerIcon, displayName: 'Coffee Maker' },
  load_dishwasher: { icon: DishwasherIcon, displayName: 'Dishwasher' },
  load_garbage_disposal: { icon: GarbageDisposalIcon, displayName: 'Garbage Disposal' },
  load_garage_door_opener: { icon: GarageDoorOpenerIcon, displayName: 'Garage Door Opener' },
  load_microwave: { icon: MicrowaveIcon, displayName: 'Microwave' },
  load_smart_powerpoint: { icon: OutletIcon, displayName: 'Smart Outlet' },
  load_washing_machine: { icon: WashingMachineIcon, displayName: 'Washing Machine' },
  load_backup_panel: { icon: BackupPanelIcon, displayName: 'Backup Panel' },
};

/**
 * Accepts a assignment to return the device icon. The default device icon is returned if there is no assignment.
 *
 * @param assignment - The device assignment
 * @returns A device Icon
 */
export function getIconForDevice(assignment?: string | null) {
  if (!assignment) return OutletIcon; // Returns the default device icon if there is no assignment

  const loadTypeMatchArray = assignment.split('__'); // ie. Parse load_spa from load_spa__1
  return CIRCUIT_TYPE_TO_UI_CONFIG[loadTypeMatchArray[0]]?.icon || OutletIcon;
}

/**
 * This is the responsive size constant we re-use to display device icons,
 * It can be used like so for device icons and their respective skeleton loader components:
 * Example component attributes: w={DEVICE_ICON_RESPONSIVE_SIZE} h={DEVICE_ICON_RESPONSIVE_SIZE}
 */
export const DEVICE_ICON_RESPONSIVE_SIZE = [270, 400];

export const MANUFACTURER_ID_SATURN = 112;
export const MANUFACTURER_ID_SCHNEIDER = 52;
export const MANUFACTURER_ID_SEM = 53;
export const MANUFACTURER_ID_SE_SEM = 157;
export const MANUFACTURER_ID_AYLA = 478;

// This is the default dimmer value we use when the dimmer value is not set/available
export const DEFAULT_DIMMER_LEVEL = 50;
