import { TeslaCommissionPayload } from 'clipsal-cortex-types/src/api';

import { baseApi } from '../../app/services/baseApi';

export const batteryApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    commissionBattery: build.mutation<
      void,
      {
        siteId: number;
        body: TeslaCommissionPayload;
      }
    >({
      query: ({ siteId, body }) => ({
        url: `/v1/sites/${siteId}/commission_battery`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['AvailableTeslaBatteries'],
    }),
    decommissionBattery: build.mutation<
      void,
      {
        siteId: number;
        body: TeslaCommissionPayload;
      }
    >({
      query: ({ siteId, body }) => ({
        url: `/v1/sites/${siteId}/decommission_battery`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['AvailableTeslaBatteries'],
    }),
  }),
});

export const { useCommissionBatteryMutation, useDecommissionBatteryMutation } = batteryApi;
