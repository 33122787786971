import React from 'react';
import { Box, Center, Heading, Text, useColorModeValue, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import ArcButton from '../../common/components/ArcButton';
import SlidingAnimationPageBase from '../../common/components/SlidingAnimationPageBase';
import { useSiteDeviceCheck } from '../../common/hooks/use-site-device-check';
import { CircleIcon, GridIcon } from '../../styles/custom-icons';
import { selectSite } from '../site/siteSlice';
import ConfirmTariffPageBase from './ConfirmTariffPageBase';
import { MIN_TARIFF_PAGE_HEIGHT } from './tariff-constants';

export function TariffSetupComplete() {
  const site = useSelector(selectSite);
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const { t } = useTranslation();
  const paramBackURL = search.get('backURL');
  const backURL = `../home?direction=back`;
  const routeBase = `/sites/${site.site_id}/account`;
  const { siteHasSaturnInverter } = useSiteDeviceCheck();

  const iconColor = useColorModeValue('primaryBranding.600', 'primaryBranding.200');

  const handleOnClick = async () => {
    if (site.isOnboarding) {
      if (siteHasSaturnInverter) {
        navigate(`${routeBase}/energy-modes/start?backURL=${routeBase}/tariff/finish`);
      } else navigate(`/sites/${site.site_id}/home`);
    } else {
      navigate(backURL);
    }
  };

  return (
    <SlidingAnimationPageBase
      title={t('Energy Rates.set up rates')}
      includeTopNav
      px={4}
      backURL={paramBackURL ?? backURL}
    >
      <VStack align={'initial'} minHeight={MIN_TARIFF_PAGE_HEIGHT}>
        <Center position="relative" mb={1} flexDirection="column" textAlign="center">
          <Box w="80px" position={'absolute'}>
            <GridIcon w="100%" h="100%" color={iconColor} data-testid={`grid-icon`} />
          </Box>
          <CircleIcon h="60%" w="60%" color={iconColor} data-testid="circle-icon" zIndex={3} />
        </Center>
        <Heading textAlign="center" fontSize={24}>
          {t('Set Up Hardware.setup successful')}
        </Heading>
        <Text textAlign="center">{t('Energy Rates.tariff setup complete')}</Text>
        <ArcButton arcColor="#3DCD58" minWidth="280px" py={6} data-testid="continue-btn" onClick={handleOnClick}>
          {t('Common.continue')}
        </ArcButton>
      </VStack>
    </SlidingAnimationPageBase>
  );
}

export default ConfirmTariffPageBase;
